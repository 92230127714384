// IMPORT==================================================================================================================
import { useState, useEffect }                                                      from "react";
// COMPONENTS==============================================================================================================
import { Header }                                                                   from "./components/Header";
import { Footer }                                                                   from "./components/Footer";
import { Loading }                                                                  from "./components/Modal";
import { BrowserRouter, Routes, Route }                                             from "react-router-dom";
// VIEW====================================================================================================================
import { AboutUs, Home, Required }                                                  from "./view/Home";
import { Shop, Cart, Details }                                                      from "./view/Shop";
import { Contact }                                                                  from "./view/Contact";
import { Admin, UserAdmin, ProductAdmin, PedidoAdmin }                              from "./view/Admin";
import { Signature, Forget, Reset }                                                 from "./view/User";

function App() {
    // VARIABLES===========================================================================================================
    const [loading, setLoading]                                                     = useState(false);
    const [cart, setCart]                                                           = useState([]);
    const user                                                                      = window.localStorage.getItem("user") === undefined ? false : JSON.parse(window.localStorage.getItem("user"));
    const rol                                                                       = user ? user.attributes.rol === "2" : false;
    const typeShop                                                                  = user ? user.attributes.declaracion.datos.tipo_dispensado : false;
    const objMax                                                                    = user ? user.attributes.suscripcion.maxGramaje : false;
    const [ultimoGramaje, setUltimoGramaje]                                         = useState(parseFloat(user ? user.attributes.suscripcion.ultimo_gramaje : 0));
    const [auth, setAuth]                                                           = useState(window.localStorage.getItem("token") === undefined ? "" : window.localStorage.getItem("token"));
    // LOGIC===============================================================================================================
    const eventCart                                                                 = (item, qty) => {

        const element       = cart.filter((element) => element.id === item.id);
        const gramajeMax    = parseFloat(typeof objMax === "object" ?  objMax[1] :  objMax);

        if(ultimoGramaje >= gramajeMax && qty === "+"){
            return;
        }

        if(element.length === 0 && (qty === "+" || qty > 0)){
            setCart([...cart, {
                id      : item.id,
                name    : item.attributes.nombre,
                price   : item.attributes.precio,
                img     : item.attributes.imgPath,
                qty     : qty === "+" ? 1 : qty
            }]);            
        }

        if(element.length > 0){
            setCart(cart.filter((element) => {
                if(element.id === item.id){
                    element.qty = qty === "+" ? element.qty + 1 : (qty === "-" ? element.qty - 1 : element.qty);
                }

                return element.qty > 0;
            }));
        }

    }

    useEffect(() => {
        const auxCart       = cart.map((item) => item.qty);
        const sum           = auxCart.reduce((accumulator, currentValue) => (accumulator + currentValue), 0);
        setUltimoGramaje((sum * parseFloat(typeof objMax === "object" ?  objMax[0] :  objMax)));
    }, [cart]);

    // RENDER==============================================================================================================
    return (
        <BrowserRouter>
            <Loading state={loading} setState={setLoading}/>
            <div className="container max-w-full text-black-bases min-h-screen flex flex-col">                
                <Header auth={auth} setAuth={setAuth} rol={rol} cart={cart} user={user} eventCart={eventCart} setLoading={setLoading}/>
                <main className="flex-grow py-10">
                    <Routes>
                        <Route path="/" element={<Home auth={auth} setAuth={setAuth} setLoading={setLoading}/>}/>
                        <Route path="/contact" element={<Contact/>}/>
                        <Route path="/about-us" element={<AboutUs/>}/>
                        <Route path="/required" element={<Required/>}/>
                        <Route path="/signature/:rut" element={<Signature setLoading={setLoading}/>}/>
                        <Route path="/forget" element={<Forget setLoading={setLoading}/>}/>
                        <Route path="/reset/:id" element={<Reset setLoading={setLoading}/>}/>
                        {auth && <Route path="/catalog" element={<Shop cart={cart} typeShop={typeShop} eventCart={eventCart} user={user}/>}/>}
                        {auth && <Route path="/cart" element={<Cart cart={cart} eventCart={eventCart} setLoading={setLoading} setCart={setCart} user={user}/>}/>}
                        {auth && <Route path="/details/:id" element={<Details/>}/>}
                        {(auth && rol) && 
                            <>
                                <Route path="/admin"        element={<Admin setLoading={setLoading}/>}/>
                                <Route path="/userAdmin"    element={<UserAdmin setLoading={setLoading}/>}/>
                                <Route path="/productAdmin" element={<ProductAdmin setLoading={setLoading}/>}/>
                                <Route path="/pedidoAdmin"  element={<PedidoAdmin setLoading={setLoading}/>}/>
                            </>
                        }
                        <Route path="*" element={<h1>Not found</h1>}/>
                    </Routes>
                </main>
                <Footer/>
            </div>
        </BrowserRouter>
    );
}

export default App;
