// IMPORT==================================================================================================================
import { useEffect, useRef, useState }                                              from "react";
import axios                                                                        from "axios";
import { formatRut, cleanRut, validateRut }                                         from "../assets/js/utils";
import { useParams }                                                                from "react-router";
// IMG=====================================================================================================================
// import logo                                                                         from "../assets/img/logo.png";
// COMPONENTS==============================================================================================================
import { Button }                                                                   from "./../components/Button";
import { Input }                                                                    from "./../components/Input";
// import { motion }                                                                   from "framer-motion";
import { Modal }                                                                    from "./../components/Modal";
import SignatureCanvas                                                              from "react-signature-canvas";

export function Acount({state, setState, setAuth, setLoading, tab}) {
    // VARIABLES===========================================================================================================
    const dialog                        = useRef();
    // LOGIC===============================================================================================================
    if(state){
        dialog.current.showModal();
        document.querySelector("html").style.overflow = "hidden";
    }

    const onClose                       = () => {
        dialog.current.close();
        document.querySelector("html").style.overflow = "";
        setState(false);
    }
    // RENDER==============================================================================================================
    return (
        <dialog className="backdrop:bg-pantone-green backdrop:opacity-80 bg-background md:w-9/12 w-full p-5 md:px-32" ref={dialog}>
            <div className="flex flex-wrap">
                <button 
                    className="text-xl absolute top-0 right-0 m-2"
                    onClick={onClose}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeWidth="1.5"><circle cx="12" cy="12" r="10"/><path strokeLinejoin="round" d="m14.5 9.5l-5 5m0-5l5 5"/></g></svg>
                </button>
                {
                    {
                        "Signup"    : <Signup onClose={onClose} setLoading={setLoading}/>,
                        "Login"     : <Login onClose={onClose} setAuth={setAuth} setLoading={setLoading}/>
                    }[tab]
                }          
            </div>
        </dialog>
    );
}

function Signup({setLoading}){
    // VARIABLES===========================================================================================================
    const [modal, setModal]                                                         = useState(false);
    // USER
    const [receta, setReceta]                                                       = useState("");
    const [name, setName]                                                           = useState("");
    const [lastName, setLastName]                                                   = useState("");
    const [rut, setRut]                                                             = useState("");
    const [direction, setDirection]                                                 = useState("");
    const [direction2, setDirection2]                                               = useState("");
    const [type, setType]                                                           = useState("119");
    const [fechaNacimiento, setFechaNacimiento]                                     = useState(0);
    const [fechaEmi, setFechaEmi]                                                   = useState(0);
    const [timepoDispensado, setTimepoDispensado]                                   = useState(0);
    const [duracionReceta, setduracionReceta]                                       = useState(0);
    const [gramajeMensual, setGramajeMensual]                                       = useState(false);
    const [gramaje, setGramaje]                                                     = useState("");

    const [gramajeDias, setGramajeDias]                                             = useState("7");
    const [gramajeCantidad, setGramajeCantidad]                                     = useState("0");
    
    const [email, setEmail]                                                         = useState("");
    const [phone, setPhone]                                                         = useState(0);
    const [diagnostico, setDiagnostico]                                             = useState("");
    const [nrmReceta, setNrmReceta]                                                 = useState("");
    const [representante, setRepresentante]                                         = useState(false);
    const [nombreMenor, setNombreMenor]                                             = useState("");
    const [rutMenor, setRutMenor]                                                   = useState("");
    const [edadMenor, setEdadMenor]                                                 = useState(0);
    const [certificadoMenor, setCertificadoMenor]                                   = useState("");
    const [message, setMessage]                                                     = useState("Formulario creado exitosamente");
    // LOGIC===============================================================================================================
    // RENDER==============================================================================================================
    return (
        <div className="w-full md:px-10">
            <Modal state={modal} setState={setModal} message={message}/>
            <h1 className="text-2xl md:text-5xl font-thin border-b-2 border-pantone-green py-3 w-full m-auto text-center md:m-0 md:text-start md:w-3/6">Solicitud de ingreso</h1>
            <form className="flex flex-wrap justify-center py-2 md:py-6 transition-all duration-300 ease-in-out">                
                <div className="w-full px-2 md:px-0 md:w-1/2 md:pr-14">
                    <Input type="text" onInput={(e) => {setRut(e.target.value)}} value={rut !== "" ? formatRut(rut) : rut} placeholder="Ingrese su rut" title="Rut"></Input>
                    <Input type="text" onInput={(e) => {setName(e.target.value)}} placeholder="Ingrese sus nombres" title="Nombre completo"></Input>
                    <Input type="text" onInput={(e) => {setLastName(e.target.value)}} placeholder="Ingrese sus apellidos" title="Apellido completo"></Input>
                    <Input type="text" onInput={(e) => {setDirection(e.target.value)}} placeholder="Ingrese la dirección" title="Dirección"></Input>
                    <Input type="text" onInput={(e) => {setDirection2(e.target.value)}} placeholder="Ingrese la dirección" title="Dirección adicional"></Input>
                    <Input type="email" onInput={(e) => {setEmail(e.target.value)}} placeholder="Ingrese su correo electrónico" title="Correo electrónico"></Input>
                    <Input type="number" onInput={(e) => {setPhone(e.target.value)}} placeholder="Ingrese su teléfono" title="Teléfono"></Input>
                    <Input type="date" onInput={(e) => {setFechaEmi(e.target.value)}} placeholder="Ingrese la duración de la receta" title="Fecha emisión de receta"></Input>
                </div>
                <div className="w-full px-2 md:px-0 md:w-1/2 md:pl-10">
                    <div className="md:py-[2.8rem] pb-8 pt-4 w-full">
                        <label className="relative inline-flex items-center cursor-pointer">
                            <input type="checkbox" value="" onChange={(e) => {                            
                                setRepresentante(e.target.checked);
                            }} className="sr-only peer"/>
                            <div className="w-11 h-6 bg-pantone-green peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-yellow-100 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-yellow-custom"></div>
                            <span className="ms-3 text-sm font-medium text-gray-900">Soy representante legal</span>
                        </label>
                    </div>
                    <div className="py-2 flex flex-wrap w-full items-center -mt-[1.94rem]">
                        <label className="text-xl font-thin mb-2">Tipo de medicación</label>
                        <select 
                            className="bg-background border-2 border-pantone-green w-full p-1" 
                            onChange={async (e) => {
                                setType(e.target.value);
                            }}    
                        >
                            <option value="119">CANNABIS</option>
                            <option value="120">PSILOCIBINA</option>
                        </select>
                    </div>
                    <Input type="date" onInput={(e) => {setFechaNacimiento(e.target.value)}} placeholder="Ingrese su fecha de nacimiento" title="Fecha de nacimiento"></Input>                    
                    <Input type="number" onInput={(e) => {setduracionReceta(e.target.value)}} placeholder="Ingrese duración de la receta" title="Duración de la receta (En meses)"></Input>
                    {type === "119" ? 
                        <Input type="number" onInput={(e) => {setGramaje(e.target.value)}} placeholder={`Ingrese el gramaje de consumo ${gramajeMensual ? "mensual" : "diario"}`} title={`Gramaje ${gramajeMensual ? "mensual" : "diario"}`} switch={true} onChange={(e) => {
                            setGramajeMensual(e.target.checked);
                        }}></Input>
                        :
                        <div className="flex flex-wrap">
                            <div className="py-2 flex flex-wrap w-full items-center md:w-1/2 md:pr-5">
                                <label className="text-xl font-thin mb-2">Tipo de medicación</label>                                
                                <select 
                                    className="bg-background border-2 border-pantone-green w-full p-1"
                                    onChange={async (e) => {
                                        setGramajeDias(e.target.value);
                                    }}    
                                >
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7" selected>7</option>
                                </select>
                            </div>
                            <div className="py-2 flex flex-wrap w-full items-center md:w-1/2">
                                <label className="text-xl font-thin mb-2">Cantidad de gramaje</label>
                                <select 
                                    className="bg-background border-2 border-pantone-green w-full p-1" 
                                    onChange={async (e) => {
                                        setGramajeCantidad(e.target.value);
                                    }}    
                                >
                                    <option value="0">0</option>
                                    <option value="0.05">0.05</option>
                                    <option value="0.1">0.1</option>
                                    <option value="0.2">0.2</option>
                                </select>
                            </div>
                        </div>
                    }
                    <Input type="text" onInput={(e) => {setDiagnostico(e.target.value)}} placeholder="Ingrese su diagnostico" title="Diagnostico (Opcional)"></Input>
                    <Input type="number" onInput={(e) => {setNrmReceta(e.target.value)}} placeholder="Ingrese el numero de la receta" title="Numero de la receta (Opcional)"></Input>
                    {!representante ?
                        <Input type="number" onInput={(e) => {setTimepoDispensado(e.target.value)}} placeholder="Ingrese en meses" title={"¿Desde hace cuanto tiempo se lleva medicando?"}></Input>
                        :
                        <Input type="file" onInput={(e) => {setReceta(e.target.files[0])}} placeholder="Ingrese su receta medica" title="Receta medica" accept="image/jpeg,image/png,application/pdf"></Input>
                    }
                </div>   
                {representante &&
                    <>
                        <div className="w-full px-2 md:px-0 md:w-1/2 md:pr-14">
                            <Input type="text" onInput={(e) => {setNombreMenor(e.target.value)}} placeholder="Ingrese el nombre del menor" title="Nombre del menor"></Input>
                            <Input type="text" onInput={(e) => {setRutMenor(e.target.value)}} value={rutMenor !== "" ? formatRut(rutMenor) : rutMenor} placeholder="Ingrese el rut del menor" title="Rut del menor"></Input>
                        </div>
                        <div className="w-full px-2 md:px-0 md:w-1/2 md:pl-10">
                            {/* <Input type="date" onInput={(e) => {setTimepoDispensado(e.target.value)}} placeholder="Ingrese desde cuando lleva consumiendo" title="Tiempo de dispensa"></Input> */}
                            <Input type="number" onInput={(e) => {setEdadMenor(e.target.value)}} placeholder="Ingrese la edad del menor" title="Edad del menor"></Input>
                            <Input type="file" onInput={(e) => {setCertificadoMenor(e.target.files[0])}} placeholder="Ingrese el certificado de nacimiento" title="Certificado de nacimiento" accept="application/pdf"></Input>
                        </div>
                    </>
                }
                {!representante &&
                    <div className="w-full px-2 md:px-0">
                        <Input type="file" onInput={(e) => {setReceta(e.target.files[0])}} placeholder="Ingrese su receta medica" title="Receta medica" accept="image/jpeg,image/png,application/pdf"></Input>
                    </div>
                }
                <Button 
                    className="md:text-xl md:px-16 mt-10 md:mt-20"
                    onClick={async(e) => {
                        e.preventDefault();
                        try{

                            let formData            = new FormData();
                            
                            if(!validateRut(formatRut(rut))){
                                setMessage("Rut invalido");
                                setModal(true);
                                return;
                            }

                            formData.append("nombre",                   name);
                            formData.append("apellido",                 lastName);
                            formData.append("run",                      formatRut(rut).replace(/\./g, ""));
                            formData.append("direccion_paciente[0]",    direction);
                            formData.append("direccion_paciente[1]",    direction2);
                            formData.append("tipo_dispensado",          type);
                            formData.append("fecha_nacimiento",         fechaNacimiento);
                            formData.append("fecha_emi_receta",         fechaEmi);
                            formData.append("duracion_receta",          duracionReceta);
                            formData.append("tiempo_dispensado",        timepoDispensado);

                            if(type === "119"){
                                formData.append("gramos_mensuales",     (gramaje * 30));
                            }else{
                                if(gramajeCantidad === "0"){
                                    setMessage("ingrese una cantidad validad de gramaje");
                                    setModal(true);
                                    return
                                }

                                formData.append("gramos_mensuales[0]",  gramajeCantidad);
                                formData.append("gramos_mensuales[1]",  (parseInt(gramajeDias) * parseFloat(gramajeCantidad) * 4).toFixed(1));
                            }
                            
                            if(gramajeMensual){
                                formData.append("gramos_mensuales",     parseFloat(gramaje / (30 * duracionReceta)).toFixed(2));
                            }

                            formData.append("email",                    email);
                            formData.append("telefono",                 phone);
                            formData.append("receta",                   receta);
                            formData.append("diagnostico",              diagnostico);
                            formData.append("numero_receta",            nrmReceta);
                            formData.append("representante",            `${representante}`);
                            formData.append("nombre_menor",             nombreMenor);
                            formData.append("edad_menor",               edadMenor);
                            formData.append("run_menor",                formatRut(rutMenor).replace(/\./g, ""));
                            formData.append("certificado_nacimiento",   certificadoMenor);
                         
                            setLoading(true);

                            let request             = await axios({
                                method              : "post",
                                url                 : "https://clubdelasmargaritas.org/api/freceta",
                                headers             : { 
                                    "Content-Type"  : "multipart/form-data" 
                                },
                                data                : formData
                            });

                            setLoading(false);

                            if(request.status !== 200){
                                console.log(request);
                                setLoading(false);
                                setMessage(request.data);
                                setModal(true);
                                return;
                            }

                            setMessage(request.data.response);
                            setModal(true);

                        }catch(ex){
                            setLoading(false);
                            console.log(ex);
                        }
                    }}
                >
                    Registrar
                </Button>
            </form>
        </div>
    );
}

function Login({onClose, setAuth, setLoading}){
    // VARIABLES===========================================================================================================
    const [rut, setRut]                                                             = useState("");
    const [password, setPassword]                                                   = useState("");
    const [message, setMessage]                                                     = useState("Ingresando al sistema");
    const [modal, setModal]                                                         = useState(false);
    // LOGIC===============================================================================================================
    // RENDER==============================================================================================================
    return (
        <div className="md:w-1/2 w-full md:px-10 m-auto">
            <Modal state={modal} setState={setModal} message={message}/>
            <h1 className="text-3xl md:text-5xl font-thin border-b-2 border-pantone-green py-3 w-3/4 text-center m-auto">Ingresar</h1>
            <form className="flex flex-wrap justify-center py-10 md:py-20">
                <Input type="text" onInput={(e) => {setRut(e.target.value)}} value={rut !== "" ? formatRut(rut) : rut}  placeholder="Ingrese su rut" title="Rut"></Input>
                <Input type="password" onInput={(e) => {setPassword(e.target.value)}} placeholder="Ingrese su contraseña" title="Contraseña"></Input>
                <a href="/forget" className="underline text-yellow-custom w-full text-center pt-2 hover:text-yellow-600">¿Olvidaste la contraseña?</a>
                <Button 
                    className="md:text-xl md:px-16 mt-10 md:mt-20"
                    onClick={async(e) => {
                        e.preventDefault();

                        try{

                            setLoading(true);

                            let request             = await axios({
                                method              : "post",
                                url                 : "https://clubdelasmargaritas.org/api/user/login",
                                data                : {
                                    "username"      : cleanRut(rut).slice(0, -1),
                                    "password"      : password
                                }
                            });

                            setLoading(false);
                            
                            if(request.status !== 200){
                                console.log(request);
                                setMessage(request.data);
                                setModal(true);
                                return;
                            }

                            if(request.data.error){
                                setMessage(request.data.response);
                                setModal(true);
                                return;
                            }

                            let data                = request.data.response;
                            // let user                = {
                            //     email               : data.email,
                            //     last_name           : data.last_name,
                            //     name                : data.name,
                            //     phone               : data.phone,
                            //     run                 : data.run,
                            //     username            : data.username
                            // };

                            delete data["password"]; 
                            window.localStorage.setItem("token", data.attributes.token);
                            window.localStorage.setItem("user", JSON.stringify(data));

                            setLoading(false);
                            setAuth(data.attributes.token);
                            onClose();

                        }catch(ex){
                            console.log(ex);
                        }

                    }}
                >
                    Ingresar
                </Button>
            </form>
        </div>
    );
}

export function Signature({setLoading}){
    // VARIABLES===========================================================================================================
    const { rut }                                                                   = useParams();
    // VARIABLES===========================================================================================================
    const [modal, setModal]                                                         = useState(false);
    // USER
    const [password, setPassword]                                                   = useState("");
    const [passwordConfirm, setPasswordConfirm]                                     = useState("");
    const [dni, setDni]                                                             = useState("");
    const [certificado, setCertificado]                                             = useState("");
    const [estadoCivil, setEstadoCivil]                                             = useState("Soltero/a");
    const [oficio, setOficio]                                                       = useState("");
    const [comuna, setComuna]                                                       = useState("");
    const [ciudad, setCiudad]                                                       = useState("");
    const [doctor, setDoctor]                                                       = useState("");
    const [rutDoctor, setRutDoctor]                                                 = useState("");
    const [oficioDoctor, setOficioDoctor]                                           = useState("");
    const [direccionDoctor, setDireccionDoctor]                                     = useState("");
    const [comunaDoctor, setComunaDoctor]                                           = useState("");
    const [ciudadDoctor, setCiudadDoctor]                                           = useState("");
    const [fechaAntecedente, setFechaAntecedente]                                   = useState("");
    const [pagoOtr, setPagoOtr]                                                     = useState(false);
    const [sign, setSign]                                                           = useState();    
    const [message, setMessage]                                                     = useState("Formulario creado exitosamente");    
    // LOGIC===============================================================================================================
    // RENDER==============================================================================================================
    return (
        <div className="w-full md:px-10">
            <Modal state={modal} setState={setModal} message={message}/>
            <h1 className="text-2xl md:text-5xl font-thin border-b-2 border-pantone-green py-3 w-full m-auto text-center md:m-0 md:text-start md:w-3/6">Declaración</h1>
            <form className="flex flex-wrap justify-center py-2 md:py-6 transition-all duration-300 ease-in-out">
                <div className="w-full px-2 md:px-0 md:w-1/2 md:pr-14">
                    <Input type="password" onInput={(e) => {setPassword(e.target.value)}} placeholder="Ingrese sus constraseña" title="Constraseña"></Input>
                    <Input type="password" onInput={(e) => {setPasswordConfirm(e.target.value)}} placeholder="Ingrese sus constraseña" title="Confirma constraseña"></Input>
                    <div className={`py-2 flex flex-wrap w-full items-center`}>
                        <label className="text-xl font-thin mb-2">Estado civil</label>
                        <select 
                            className="bg-background border-2 border-pantone-green w-full p-1" 
                            onChange={async (e) => {
                                setEstadoCivil(e.target.value);
                            }}
                        >
                            <option value="Soltero/a">Soltero/a</option>
                            <option value="Casado/a">Casado/a</option>
                            <option value="Viudo/a">Viudo/a</option>
                            <option value="Divorciado/a">Divorciado/a</option>
                            <option value="Divorciado/a">Separado/a</option>
                        </select>
                    </div>
                    <Input type="text" onInput={(e) => {setOficio(e.target.value)}} placeholder="Ingrese sus oficio o profesión" title="Oficio o profesión"></Input>
                    <Input type="text" onInput={(e) => {setComuna(e.target.value)}} placeholder="Ingrese sus comuna" title="Comuna"></Input>
                    <Input type="text" onInput={(e) => {setCiudad(e.target.value)}} placeholder="Ingrese sus ciudad" title="Ciudad"></Input>
                    <Input type="file" onChange={(e) => {setDni(e.target.files)}} title="Carnet de identidad" multiple accept="image/png, image/jpeg"></Input>
                </div>
                <div className="w-full px-2 md:px-0 md:w-1/2 md:pl-10">                    
                    <Input type="text" onInput={(e) => {setDoctor(e.target.value)}} placeholder="Ingrese el nombre del doctor" title="Nombre del doctor"></Input>
                    <Input type="text"  onInput={(e) => {setRutDoctor(e.target.value)}} value={rut !== "" ? formatRut(rutDoctor) : rutDoctor} placeholder="Ingrese el rut del doctor" title="Rut del doctor"></Input>
                    <Input type="text"  onInput={(e) => {setOficioDoctor(e.target.value)}} placeholder="Ingrese el oficio del doctor" title="Oficio del doctor"></Input>
                    <Input type="text"  onInput={(e) => {setDireccionDoctor(e.target.value)}} placeholder="Ingrese la dirección del doctor" title="Dirección del doctor"></Input>
                    <Input type="text"  onInput={(e) => {setCiudadDoctor(e.target.value)}} placeholder="Ingrese la ciudad del doctor" title="Ciudad del doctor"></Input>
                    <Input type="text"  onInput={(e) => {setComunaDoctor(e.target.value)}} placeholder="Ingrese la comuna del doctor" title="Comuna del doctor"></Input>
                    <Input type="file" onChange={(e) => {setCertificado(e.target.files[0])}} title="Certificado de antecedente" multiple accept="application/pdf"></Input>
                </div>              
                {/* <Input type="date"  onInput={(e) => {setFechaAntecedente(e.target.value)}} placeholder="Ingrese la fecha de expiración del antecedente" title="Fecha de expiración del antecedente"></Input> */}
                <div className="py-5 flex flex-wrap w-full items-center px-2 md:px-0 ">
                    <Input type="date"  onInput={(e) => {setFechaAntecedente(e.target.value)}} placeholder="Ingrese la fecha de emisión del antecedente" title="Fecha de emisión del antecedente"></Input>
                    <div className="w-full flex flex-wrap justify-center text-center">
                        <div className="pb-8 pt-4 w-full">
                            <label className="relative inline-flex items-center cursor-pointer">
                                <input type="checkbox" value="" onChange={(e) => {                            
                                    setPagoOtr(e.target.checked);
                                }} className="sr-only peer"/>
                                <div className="w-11 h-6 bg-pantone-green peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-yellow-100 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-yellow-custom"></div>
                                <span className="ms-3 text-sm font-medium text-gray-900">Voy a donar desde una cuenta que no es la mía</span>
                            </label>
                        </div>
                    </div>
                    <div className="w-full flex flex-wrap justify-center">
                        <label className="text-xl font-thin mb-2">Firma el documento</label>
                        <button 
                            className   = "mx-2 bg-pantone-green text-background px-4 py-1 hover:opacity-90 mb-2"
                            type        = "button"
                            onClick     = {() => {
                                sign.clear();
                            }}
                        >
                            Limpiar firma
                        </button>
                    </div>                    
                    <div className="w-full">
                        <SignatureCanvas 
                            penColor    = "#494949" 
                            canvasProps = {{width: (window.innerWidth < 1000 ? 300 : 1000), height: 300, className: "sigCanvas border-2 border-pantone-green m-auto"}} 
                            ref         = {(data) => {
                                setSign(data);
                            }}
                        />
                    </div>
                </div>
                <Button 
                    className="md:text-xl md:px-16 mt-10 md:mt-20"
                    onClick={async(e) => {
                        e.preventDefault();
                        try{

                            let formData            = new FormData();
                            
                            if(!validateRut(formatRut(rut))){
                                return;
                            }

                            setLoading(true);

                            formData.append("run",                      formatRut(rut).replace(/\./g, ""));
                            formData.append("password",                 password);
                            formData.append("dni[0]",                   dni[0]);
                            formData.append("dni[1]",                   dni[1]);
                            formData.append("certificado",              certificado);
                            formData.append("estado_civil",             estadoCivil);
                            formData.append("oficio_paciente",          oficio);
                            formData.append("comuna_paciente",          comuna);
                            formData.append("ciudad_paciente",          ciudad);
                            formData.append("nombre_medico",            doctor);
                            formData.append("run_medico",               formatRut(rutDoctor).replace(/\./g, ""));
                            formData.append("oficio_medico",            oficioDoctor);
                            formData.append("direccion_medico",         direccionDoctor);
                            formData.append("comuna_medico",            comunaDoctor);
                            formData.append("ciudad_medico",            ciudadDoctor);
                            // formData.append("fecha_exp_antecedente",    fechaAntecedente);
                            formData.append("fecha_emi_antecedente",    fechaAntecedente);
                            formData.append("firma",                    sign.toDataURL());
                            formData.append("pago_foraneo",             pagoOtr);

                            if(password !== passwordConfirm){
                                setMessage("Las contraseña no coincide");
                                setModal(true);
                                setLoading(false);
                                return;
                            }  

                            if(sign.isEmpty()){
                                setMessage("La firma esta vacia");
                                setModal(true);
                                setLoading(false);
                                return;
                            }
                            

                            let request             = await axios({
                                method              : "post",
                                url                 : "https://clubdelasmargaritas.org/api/form/update",
                                headers             : { 
                                    "Content-Type"  : "multipart/form-data" 
                                },
                                data                : formData
                            });                                                   

                            setLoading(false);

                            if(request.status === 403){
                                console.log(request);
                                setMessage("Usuario en espera por el administrador");
                                setModal(true);
                                return;
                            }

                            if(request.status !== 200){
                                console.log(request);
                                setMessage(request.data);
                                setModal(true);
                                return;
                            }

                            if(request.data.error){
                                setMessage(request.data.response);
                                setModal(true);
                                return;
                            }

                            setMessage("Declaración firmada correctamente");
                            setModal(true);

                        }catch(ex){
                            console.log(ex);
                        }
                    }}
                >
                    Registrar
                </Button>
            </form>
        </div>
    );
}

export function Forget({setLoading}) {

    // VARIABLES===========================================================================================================

    const [modal, setModal]                                                         = useState(false);
    const [rut, setRut]                                                             = useState("");
    const [message, setMessage]                                                     = useState("Formulario creado exitosamente");

    return (        
        <section className="w-full md:px-10">
            <form className="flex flex-wrap justify-center py-2 md:py-6 transition-all duration-300 ease-in-out md:w-[50%] m-auto">
                <Modal state={modal} setState={setModal} message={message}/>
                <Input type="text" onInput={(e) => {setRut(e.target.value)}} value={rut !== "" ? formatRut(rut) : rut} placeholder="Ingrese su rut" title="Rut"></Input>
                <Button 
                    className="md:text-xl md:px-16 mt-10 md:mt-20"
                    onClick={async(e) => {
                        e.preventDefault();
                        try{

                            let formData            = new FormData();

                            setLoading(true);

                            formData.append("run",  formatRut(rut).replace(/\./g, ""));

                            let request             = await axios({
                                method              : "post",
                                url                 : "https://clubdelasmargaritas.org/api/user/psw/forgot",
                                headers             : { 
                                    "Content-Type"  : "multipart/form-data" 
                                },
                                data                : formData
                            });                                                   

                            setLoading(false);

                            if(request.status !== 200){
                                console.log(request);
                                setMessage(request.data);
                                setModal(true);
                                return;
                            }

                            setMessage(request.data.response);
                            setModal(true);

                        }catch(ex){
                            console.log(ex);
                        }
                    }}
                >
                    Recuperar contraseña
                </Button>
            </form>
        </section>
    );
}

export function Reset({setLoading}) {

    // VARIABLES===========================================================================================================
    const { id }                                                                    = useParams();
    const [modal, setModal]                                                         = useState(true);
    const [password, setPassword]                                                   = useState("");
    const [passwordConfirm, setPasswordConfirm]                                     = useState("");
    const [goBack, setGoBack]                                                       = useState(true);
    const [message, setMessage]                                                     = useState("Formulario creado exitosamente");

    useEffect(() => {

        const getUser           = async () => {
            let request         = await axios({
                method          : "post",
                url             : `https://clubdelasmargaritas.org/api/user/get/${id}`
            });

            if(request.status !== 200){
                setMessage(request.data);
                setModal(true);
                setGoBack(true);
                return;
            }

            let aux     = request.data.response;

            if(aux.id === 0){
                setMessage("No se ha encontrado el usuario");
                setModal(true);
                setGoBack(true);
                return;
            }

            if(aux.attributes.forgotPSW === ""){
                setMessage("usted no ha pedido el reseteo de la contraseña");
                setModal(true);
                setGoBack(true);
                return;
            }

            setModal(false);
            setGoBack(false);

            console.log(new Date(aux.attributes.forgotPSW));

        }

        getUser();

    }, []);

    return (        
        <section className="w-full md:px-10">
            <Modal state={modal} setState={setModal} message={message} goBack={goBack}/>
            <form className="flex flex-wrap justify-center py-2 md:py-6 transition-all duration-300 ease-in-out md:w-[50%] m-auto">
                <Input type="password" onInput={(e) => {setPassword(e.target.value)}} placeholder="Ingrese sus constraseña" title="Constraseña"></Input>
                <Input type="password" onInput={(e) => {setPasswordConfirm(e.target.value)}} placeholder="Ingrese sus constraseña" title="Confirma constraseña"></Input>
                <Button 
                    className="md:text-xl md:px-16 mt-10 md:mt-20"
                    onClick={async(e) => {
                        e.preventDefault();
                        try{

                            let formData            = new FormData();

                            setLoading(true);

                            formData.append("id",           id);
                            formData.append("password",     password);

                            if(password !== passwordConfirm){
                                setMessage("Las contraseña no coincide");
                                setModal(true);
                                return;
                            }

                            let request             = await axios({
                                method              : "post",
                                url                 : "https://clubdelasmargaritas.org/api/user/psw/reset",
                                headers             : { 
                                    "Content-Type"  : "multipart/form-data" 
                                },
                                data                : formData
                            });

                            setLoading(false);

                            if(request.status !== 200){
                                console.log(request);
                                setMessage(request.data);
                                setModal(true);
                                return;
                            }

                            setMessage(request.data.response);
                            setModal(true);

                        }catch(ex){
                            console.log(ex);
                        }
                    }}
                >
                    Reestablecer contraseña
                </Button>
            </form>
        </section>
    );
}
