export function Input(props) {
    // RENDER==============================================================================================================
    return (
        <div className={`py-2 flex flex-wrap w-full`}>
            {!props.switch ? 
                <label className="w-full text-xl font-thin mb-2">{props.title}</label>
                :
                <div className="mb-1 flex flex-wrap">
                    <label className="text-xl font-thin md:w-auto w-full">{props.title}</label>
                    <div className="md:ml-2 mt-1">
                        <label className="relative inline-flex items-center cursor-pointer">
                            <input type="checkbox" value="" onChange={props.onChange} className="sr-only peer"/>
                            <div className="w-11 h-6 bg-pantone-green peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-yellow-100 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-yellow-custom"></div>
                            <span className="ms-3 text-sm font-medium text-gray-900">Gramaje indicado (mensual o diario)</span>
                        </label>
                    </div>
                </div>
            }
            {
                {
                    "text"      : <input className="bg-transparent border-2 border-pantone-green w-full p-1" {...props}/>,
                    "password"  : <input className="bg-transparent border-2 border-pantone-green w-full p-1" {...props}/>,
                    "number"    : <input className="bg-transparent border-2 border-pantone-green w-full p-1" {...props}/>,
                    "email"     : <input className="bg-transparent border-2 border-pantone-green w-full p-1" {...props}/>,
                    "date"      : <input className="bg-transparent border-2 border-pantone-green w-full p-1 h-[35px]" {...props}/>,
                    "file"      : <input className="px-3 py-1 transition duration-100 file:bg-pantone-green file:-m-3 file:border-0 file:p-3 file:text-white file:transition file:duration-100 file:[margin-inline-end:0.75rem] hover:file:opacity-70 border-2 w-full border-pantone-green" {...props}/>
                }[props.type]
            }            
        </div>
    );
}