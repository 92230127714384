// IMPORT==================================================================================================================
import { useState }                                                                 from "react";
// IMG=====================================================================================================================
export function Card({item, cart, eventCart}) {
    // VARIABLES===========================================================================================================
    const [isOpen, setIsOpen]               = useState(false);
    const element                           = cart.filter((element) => item.id === element.id);
    const description                       = item.attributes.descripcion;
    const height                            = description.length > 150 ? 9 : 12;
    const className                         = `max-h-[${height}rem] min-h-[${height}rem]`;
    // LOGIC===============================================================================================================
    // RENDER==============================================================================================================
    return (
        <div>
            <div className={`rounded overflow-hidden shadow-lg max-w-[18rem] min-w-[18rem] mb-10 ${!isOpen ? "min-h-[33rem] max-h-[33rem]" : ""}`}>
                <img className="w-72 h-56 m-auto" src={item.attributes.imgPath} alt="Sunset in the mountains"/>
                <div className="px-6 py-4">
                    <div className="text-xl mb-2 text-center">{item.attributes.nombre}</div>
                    <hr className="w-1/2 m-auto border-pantone-green border-2 mb-5"/>
                    <div className={`flex flex-col justify-between ${!isOpen ? "h-[14rem]" : ""}`}>
                        <p className={`text-gray-700 text-base text-center font-thin ${!isOpen ? className : ""} overflow-hidden`}>
                            {item.attributes.descripcion}
                        </p>
                        {(height === 9) &&
                            <button className="text-center w-full pt-1" onClick={() => setIsOpen(!isOpen)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24" className={`rotate-${isOpen ? "0" : "180"} m-auto`}>
                                    <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="m19 15l-7-6l-7 6"></path>
                                </svg>
                            </button>   
                        }
                        <h1 className="text-center w-full text-xl py-2 italic">${item.attributes.precio}</h1>
                    </div>                    
                </div>           
            </div>
            <div className="relative flex items-center max-w-[8rem] m-auto my-2">
                <button type="button" className="hover:bg-pantone-green hover:text-white text-pantone-green p-3 h-11" onClick={() => {
                    eventCart(item, "-");
                }}>
                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h16"/>
                    </svg>
                </button>
                <input 
                    type="text" 
                    className="bg-transparent h-11 text-center w-full py-2.5 border-4 border-pantone-green" 
                    placeholder="999" 
                    required 
                    value={element.length > 0 ? element[0].qty : "0"} 
                    onChange={(e) => {
                        eventCart(item, e.target.value);
                    }}
                />
                <button type="button" className="hover:bg-pantone-green hover:text-white text-pantone-green p-3 h-11" onClick={() => {
                    eventCart(item, "+");
                }}>
                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16"/>
                    </svg>
                </button>
            </div>
        </div>
    );
}
