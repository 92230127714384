// IMPORT==================================================================================================================
import { useEffect, useState }                                                      from "react";
import axios                                                                        from "axios";
// COMPONENTS==============================================================================================================
import { Link, useParams }                                                          from "react-router-dom";
import { Card }                                                                     from "./../components/Card";
import { Button }                                                                   from "./../components/Button";
import { Input }                                                                    from "./../components/Input";
import { Modal }                                                                    from "./../components/Modal";

export function Shop({cart, eventCart, typeShop}) {
    // VARIABLES===========================================================================================================
    const [items, setItems]                                                     = useState([]);   
    // LOGIC===============================================================================================================
    useEffect(() => {
        const getItems = async () => {
            let request             = await axios({
                method              : "post",
                url                 : "https://clubdelasmargaritas.org/api/product/getf",
            });
            setItems(request.data.response);
        };
        getItems();
    }, []);
    // RENDER==============================================================================================================
    return (
        <>
            {/* <section id="start" className="w-full mt-10 py-10 flex flex-wrap">
                <h1 className="text-4xl w-full text-center">Destacado</h1>
                <div className="border-2 border-pantone-green p-10 w-10/12 m-auto my-10">
                </div>
            </section> */}
            <section id="items" className="w-full py-10">
                <div className="py-2 flex flex-wrap w-full px-10 md:px-20 justify-center md:justify-start pb-5">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-10 mr-2 text-pantone-green" viewBox="0 0 24 24"><g transform="translate(24 0) scale(-1 1)"><path fill="currentColor" d="M10 4a6 6 0 1 0 0 12a6 6 0 0 0 0-12zm-8 6a8 8 0 1 1 14.32 4.906l5.387 5.387a1 1 0 0 1-1.414 1.414l-5.387-5.387A8 8 0 0 1 2 10z"/></g></svg>
                    <input className="bg-transparent border-2 border-pantone-green w-8/12 md:w-2/6 p-1 mr-2" placeholder="¿Que es lo que estas buscando?" onInput={async(e) => {
                        let name                = e.target.value.replace(" ", "%");
                        let request             = await axios({
                            method              : "post",
                            url                 : name === "" ? "https://clubdelasmargaritas.org/api/product/getf" : "https://clubdelasmargaritas.org/api/product/find",   
                            data                : {
                                "nombre"        : `%${name}%`
                            }
                        });
                        setItems(typeof request.data.response  === "object" ? request.data.response : []);
                    }}/>
                    <Button className="px-11">Buscar</Button>
                </div>
                <div className="inline-flex items-center justify-center w-full mb-10 md:mb-20">
                    <hr className="w-11/12 h-px my-8 bg-gray-200 border-2 border-pantone-green"/>
                    <h1 className="absolute px-3 -translate-x-1/2 bg-background left-1/2 text-5xl -mt-4 md:text-7xl md:-mt-6 font-thin">Cepas</h1>
                </div>
                <div className="w-full flex flex-wrap justify-around px-10 md:px-20">
                    {(items.length === 0) ? 
                        <div className="text-2xl">No se ha encontrado producto</div>
                    :
                        items.map((item, key) => {
                            
                            if(typeShop !== item.attributes.tipo_dispensado){
                                return;
                            }
                            
                            if(item.enabled === "0"){
                                return;
                            }
                            
                            return (
                                <Card item={item} key={key} cart={cart} eventCart={eventCart}/>
                            );
                        })
                    }
                </div>
            </section>
        </>
    );
}

export function Cart({cart, setCart, setLoading, user}) {
    // VARIABLES===========================================================================================================
    const [direccion, setDireccion]                                     = useState("Santiago 12345"); 
    const [comentario, setComentario]                                   = useState("");
    const [modal, setModal]                                             = useState(false);
    const [honorario, setHonorario]                                     = useState(false);
    const [options, setOptions]                                         = useState([]);
    const [valueLocation, setValueLocation]                             = useState([]);
    // LOGIC===============================================================================================================
    useEffect(() => {
        const getLocation           = async () => {
            let request             = await axios({
                method              : "get",
                url                 : "https://apiprod.starkenpro.cl/agency/agencyDls/localidades"
            });
            setOptions(request.data.data.reduce((groups, item) => {
                const { COMUNA }    = item;
                if (!groups[COMUNA]) {
                    groups[COMUNA]  = [];
                }
                groups[COMUNA].push(item);
                return groups;
            }, {}));
        }
        getLocation();
    }, []);
    // RENDER==============================================================================================================
    return (
        <>
            {/* <section id="start" className="w-full mt-10 py-10 flex flex-wrap">
                <h1 className="text-4xl w-full text-center">Destacado</h1>
                <div className="border-2 border-pantone-green p-10 w-10/12 m-auto my-10">
                </div>
            </section> */}
            <section id="cart" className="w-full py-10">
                <Modal state={modal} setState={setModal} message="Pedido enviado exitosamente, llegará un correo electronico a su casilla."/>
                <div className="inline-flex items-center w-full mb-10 md:mb-20">
                    <hr className="w-10/12 md:w-10/12 h-px my-8 bg-gray-200 border-2 border-pantone-green ml-16"/>
                    <h1 className="absolute px-3 bg-background text-3xl -mt-3 md:text-4xl md:-mt-4 font-thin ml-16">Datos de envio</h1>
                </div>
                <div className="w-full flex flex-wrap justify-around px-10 md:px-20">
                    <div className="w-1/2">
                        {/* <div className="pb-2 flex flex-wrap w-full">
                            <label className="w-full text-xl font-thin mb-2">Comuna</label>
                            <select 
                                className="bg-background border-2 border-pantone-green w-full p-1" 
                                onChange={async (e) => {
                                    // let request                     = await axios({
                                    //     method                      : "get",
                                    //     url                         : "https://apiprod.starkenpro.cl/quote/caja-tarifa/",
                                    //     data                        : {
                                    //         "entrega"               : "DOMICILIO",
                                    //         "tipoServicio"          : "NORMAL",
                                    //         "codigoAgenciaDestino"  : options[item][0]["COMUCODIGO"],
                                    //         "codigoAgenciaOrigen"   : 1384,
                                    //         "rutCliente"            : "",
                                    //         "codigoTipoPago"        : 1,
                                    //         "codigoCiudadOrigen"    : 1,
                                    //         "codigoCiudadDestino"   : 1,
                                    //         "rutDestinatario"       : "",
                                    //         "encargos"              : [
                                    //             {
                                    //                 "tipoEncargo"   : "2",
                                    //                 "alto"          : "10",
                                    //                 "ancho"         : "5",
                                    //                 "largo"         : "15",
                                    //                 "kilos"         : "0.15"
                                    //             }
                                    //         ]
                                    //     }
                                    // });
                                    // console.log(request);
                                    console.log(e.target.value);
                                }}    
                            >
                                {Object.keys(options).map((item, key) => {
                                    // console.log(options[item][0]["AGENCODIGO"]);
                                    if(item === "SANTIAGO"){
                                        return(
                                            <option key={key} value={options[item][0]["AGENCODIGO"]} selected>{item}</option>
                                        );
                                    }else{
                                        return(
                                            <option key={key} value={options[item][0]["AGENCODIGO"]}>{item}</option>
                                        );
                                    }

                                })}
                            </select>
                        </div>                         */}
                        {/* <Input 
                            type="text" 
                            placeholder="Dirección de despacho" 
                            title="Dirección"
                            onInput={(e)=>{setDireccion(e.target.value)}}
                        ></Input> */}
                        {user.attributes.suscripcion.honorario &&
                            <div className="w-full">
                                <label className="relative inline-flex items-center cursor-pointer">
                                    <input type="checkbox" value="" onChange={(e) => {                            
                                        setHonorario(e.target.checked);
                                    }} className="sr-only peer"/>
                                    <div className="w-11 h-6 bg-pantone-green peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-yellow-100 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-yellow-custom"></div>
                                    <span className="ms-3 text-sm font-medium text-gray-900">Retiro al local</span>
                                </label>
                            </div>
                        }
                        {honorario ? 
                            <div className="py-2">
                                <h1 className="text-2xl font-thin py-2">Domicilio : Santiago 12345</h1>
                                <hr className="border-pantone-green border-2"/>
                            </div>
                            :
                            <Input 
                                type="text" 
                                placeholder="Dirección de despacho" 
                                title="Dirección"
                                defaultValue={user.attributes.declaracion.datos.direccion_paciente[0]}
                                onInput={(e)=>{setDireccion(e.target.value)}}
                            ></Input>
                        } 
                        <div className="py-2">
                            <h1 className="text-2xl font-thin py-2">Información de contacto</h1>
                            <hr className="border-pantone-green border-2"/>
                        </div>
                        <Input type="text" placeholder="Correo électronico" title="Correo électronico" defaultValue={user.attributes.declaracion.datos.email}></Input>
                        <Input type="text" placeholder="Celular" title="Celular" defaultValue={user.attributes.declaracion.datos.telefono}></Input>
                        <div className="py-2 flex flex-wrap w-full">
                            <label className="w-full text-xl font-thin mb-2">Comentario</label>
                            <textarea
                                className="border-2 border-pantone-green p-2.5 rounded w-full text-xl bg-transparent"
                                cols="30"
                                rows="5"
                                placeholder="escriba su comentario"
                                onInput={(e)=>{setComentario(e.target.value)}}
                            ></textarea>
                        </div>
                    </div>
                    <div className="w-1/2">
                        <div className="border-2 border-pantone-green w-10/12 m-auto">
                            <h2 className="text-2xl p-2">Lista de productos</h2>
                            <div className="px-2">
                                {cart.map((item, key) => {
                                    return(
                                        <div className="border-2 border-pantone-green flex flex-wrap mb-5" key={key}>
                                            <img src={item.img} className="w-1/4 h-1/3 m-auto" alt="" />
                                            <div className="w-2/4">
                                                <div className="flex flex-wrap py-8">
                                                    <label className="font-thin text-2xl w-full text-center py-2">{item.name}</label>
                                                    <label className="text-3xl italic font-thin w-full text-center py-1">${item.price * item.qty}</label>
                                                </div>
                                            </div>
                                            <div className="w-1/4">
                                                <div className="flex flex-wrap justify-center items-center h-full">
                                                    <div className="border-2 m-auto text-2xl border-pantone-green p-2 px-4 font-thin italic">
                                                        {item.qty}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}                                
                            </div>
                            <div className="flex flex-wrap p-2 py-10 justify-around">
                                <label className="text-3xl w-1/3">Total</label>
                                <label className="font-thin text-3xl w-1/2 -ml-14">${
                                    cart.reduce(
                                        (accumulator, currentValue) => accumulator + (currentValue.qty * currentValue.price),
                                        0,
                                    )
                                }</label>
                            </div>
                        </div>
                        <div className="flex flex-wrap justify-center p-4">
                            <Button 
                                className="text-3xl font-thin p-4 px-14"
                                onClick={async(e ) => {
                                    setLoading(true);
                                    let request             = await axios({
                                        method              : "post",
                                        url                 : "https://clubdelasmargaritas.org/api/pedido/create",
                                        data                : {
                                            "id_user"       : 49,
                                            "direccion"     : direccion,
                                            "detalles"      : cart,
                                            // "total"         : cart.reduce(
                                            //     (accumulator, currentValue) => accumulator + (currentValue.qty * currentValue.price),
                                            //     0,
                                            // ),
                                            "metodo_pago"   : "Transferencia",
                                            "estado"        : 1,
                                            "comentario"    : comentario
                                        }
                                    });
                                    
                                    setLoading(false);

                                    if(request.status !== 200){
                                        console.log(request);
                                        return;
                                    }
        
                                    if(request.data.error){
                                        return;
                                    }

                                    window.location.assign(request.data.response);
                                    // setDireccion("");
                                    // setComentario("");
                                    // setModal(true);
                                    // setCart([]);
                                    // response
                                }}
                            >Ir a donar</Button>
                            <Link to="/catalog" className="w-full text-center p-2 text-xl text-yellow-custom pt-2 hover:text-yellow-600">Ver más productos</Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export function Details() {
    // VARIABLES===========================================================================================================
    const { id }                    = useParams();
    const [pedido, setPedido]       = useState({});
    // LOGIC===============================================================================================================
    useEffect(() => {
        const getPedido             = async () => {
            let request             = await axios({
                method              : "post",
                url                 : `https://clubdelasmargaritas.org/api/pedido/get/${id}`
            });

            if(request.status !== 200){
                console.log(request);
                return;
            }

            if(request.data.error){
                return;
            }

            let data                = request.data.response;
            console.log(data);
            setPedido(data);
        }
        getPedido();
    }, []);
    // RENDER==============================================================================================================
    return (
        <section className="w-full md:px-10">
            <h1 className="border-b-4 border-pantone-green p-5 text-2xl lg:w-2/6 w-10/12 text-center m-auto">Detalle del pedido</h1>
            <div className="lg:w-2/6 w-10/12 p-5 m-auto">
                {pedido &&
                    <>
                        {pedido.detalles.map((item, key) => {
                            return(
                                <div className="border-2 border-pantone-green flex flex-wrap mb-5" key={key}>
                                    <img src={item.img} className="w-1/4 h-1/3 m-auto" alt="" />
                                    <div className="w-2/4">
                                        <div className="flex flex-wrap py-8">
                                            <label className="font-thin text-2xl w-full text-center py-2">{item.name}</label>
                                            <label className="text-3xl italic font-thin w-full text-center py-1">${item.price * item.qty}</label>
                                        </div>
                                    </div>
                                    <div className="w-1/4">
                                        <div className="flex flex-wrap justify-center items-center h-full">
                                            <div className="border-2 m-auto text-2xl border-pantone-green p-2 px-4 font-thin italic">
                                                {item.qty}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        <div className="border-t-4 border-pantone-green p-5 text-2xl  m-auto w-full">
                            <div className="flex flex-wrap w-full">
                                <div className="w-1/2 text-end px-2">
                                    Total
                                </div>
                                <div className="w-1/2 px-2">
                                    : ${pedido.total}
                                </div>
                            </div>
                            <div className="flex flex-wrap w-full">
                                <div className="w-1/2 text-end px-2">
                                    Direccion
                                </div>
                                <div className="w-1/2 px-2">
                                    : {pedido.direccion}
                                </div>
                            </div>
                            <div className="flex flex-wrap w-full">
                                <div className="w-1/2 text-end px-2">
                                    Estado
                                </div>
                                <div className="w-1/2 px-2">
                                    : {pedido.estado === "1" ? "Pagado" : "No pagado"}
                                </div>
                            </div>
                            <div className="flex flex-wrap w-full">
                                <div className="w-1/2 text-end px-2">
                                    Comentario
                                </div>
                                <div className="w-1/2 px-2">
                                    : {pedido.comentario}
                                </div>
                            </div>
                        </div>
                    </>                    
                } 
            </div>
        </section>
    );
}
