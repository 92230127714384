// IMPORT==================================================================================================================
import { useState }                                                                 from "react";
import axios                                                                        from "axios";
// COMPONENTS==============================================================================================================
import { Button }                                                                   from "./../components/Button";
import { Modal }                                                                   from "./../components/Modal";

export function Contact() {
    // VARIABLES===========================================================================================================
    const [body, setBody]                                                           = useState("");
    const [mail, setMail]                                                           = useState("");
    const [name, setName]                                                           = useState("");
    const [modal, setModal]                                                         = useState(false);
    // RENDER==============================================================================================================
    return (
        <section className="w-full flex items-center justify-center flex-wrap py-28">
            <Modal state={modal} setState={setModal} message="Correo enviado exitosamente."/>
            <h1 className="p-5 text-2xl w-full text-center m-auto">¿Como podemos ayudarte?</h1>
            <div className="w-3/5 flex flex-wrap justify-end">
                <h1 className="w-full text-3xl pb-3">Cuentanos que necesitas</h1>
                <textarea
                    className="border-2 border-pantone-green p-2.5 rounded w-full text-xl bg-transparent"
                    cols="30"
                    rows="10"
                    placeholder="Escribe en este cuadro de texto"
                    onInput={(e) => setBody(e.target.value)}
                ></textarea>
                <input type="text" placeholder="Nombre" className="border-2 border-pantone-green p-1 rounded md:w-1/3 md:my-5 w-full mt-5 bg-transparent" onInput={(e) => setName(e.target.value)}/>
                <input type="email" placeholder="Correo electronico" className="border-2 border-pantone-green p-1 rounded md:w-1/3 md:my-5 md:ml-5 w-full my-5 bg-transparent" onInput={(e) => setMail(e.target.value)}/>
                <div className="w-full flex">
                    <Button className="ml-auto" onClick={async() => {
                        try{
                            let request     = await axios({
                                method          : "post",
                                url             : "https://clubdelasmargaritas.org/api/email/contact",
                                data            : {
                                    "to"        : mail,
                                    "name"      : name,
                                    "body"      : body
                                }
                            });

                            if(request.status !== 200){
                                console.log(request);
                                return;
                            }

                            setModal(true);

                        }catch(ex){
                            console.log(ex);
                        }
                    }}>
                        Enviar
                    </Button>
                </div>
            </div>
        </section>
    );
}
