// IMPORT==================================================================================================================
export function Footer(){
    return(
        <>
            <hr className="border-b-4 border-pantone-green lg:w-2/6 w-10/12 m-auto"/>
            <footer className="flex flex-wrap justify-between">
                <p className="px-10 pt-10 pb-5 lg:w-1/3 w-full">
                    {/* Asociación de usuarios de plantas medicinales. Club de las Margaritas
                    <br/>
                    Club cannábico medicinal y organización sin fines de lucro, inscrita 
                    legalmente en el servicio de registro civil en abril de 2021 y en el 
                    servicio de impuestos internos.
                    Dedicados a producir y distribuir cannabis medicinal de calidad
                    entre nuestro pacientes. */}

                    Asociación de usuarios de plantas medicinales. Club de las Margaritas
                    Club cannábico medicinal y organización sin fines de lucro, 
                    inscrita legalmente en el servicio de registro civil en abril 
                    de 2021 y en el servicio de impuestos internos. Dedicados a producir y 
                    distribuir cannabis medicinal de calidad entre nuestro pacientes.
                    Asociación Club de Las Margaritas, es una comunidad privada sin fines de lucro, 
                    de usuarios de medicina natural, con Persona Jurídica Vigente inscrita en el 
                    Servicio de Registro Civil y en el Servicio de Impuestos Internos desde el año 2021.
                    Nuestro fin es mejorar la calidad de vida de nuestros asociados, 
                    por medio del uso de medicina natural de alta calidad, 
                    para el uso exclusivo de nuestros miembros, es decir, 
                    sólo para aquellas personas que han cumplido con los requisitos y 
                    que han aceptado los términos y condiciones para ser parte de nuestra comunidad.
                </p>
                <div className="px-10 p-5 md:pt-5">
                    <label className="md:text-lg">CONTACTO</label>
                    <br/>
                    <a className="md:text-lg" href="mailto:Clubdelamargaritas@gmail.com?subject=Contacto desde la web">clubdelasmargaritas@gmail.com</a>
                    <br/>
                    <a className="md:text-lg" href="https://wa.link/347s0z" target="_blank">+569 4298 7053</a>
                    <div>
                        Requisitos para ser miembro:<br/>
                        - Receta médico Vigente<br/>
                        - Certificado de antecedentes<br/>
                        - Cédulas de identidad por ambos lados<br/>
                    </div>
                </div>
                <p className="text-center my-2 w-full">
                    © {new Date().getFullYear()} <a href="https://ddsolutions.cl/" className="text-blue-500">DigitalDevSolutions</a> - Aplicaciones Tecnológicas Ltda · Todos los derechos reservados
                </p>
            </footer>
        </>
    );
}