// IMPORT==================================================================================================================
import { useState, useRef, useEffect }                                              from "react";
import axios                                                                        from "axios";
// COMPONENTS==============================================================================================================
import { NavLink, Link, useLocation }                                               from "react-router-dom";
import { Modal }                                                                    from "./Modal";
// IMG=====================================================================================================================
import logo                                                                         from "../assets/img/logo.png";
export function Header({auth, setAuth, cart, eventCart, setLoading, rol, user}){
    // VARIABLES===========================================================================================================
    const shoppingRef                                                               = useRef(null);
    const accountRef                                                                = useRef(null);
    const [menuMovil, setMenuMovil]                                                 = useState(false);
    const [shpping, setShopping]                                                    = useState(false);
    const [account, setAccount]                                                     = useState(false);
    const [newsLetter, setNewsLetter]                                               = useState(user ? (user.attributes.suscripcion.notificaciones) : false);
    const [modal, setModal]                                                         = useState(false);
    const [message, setMessage]                                                     = useState("Formulario creado exitosamente");    
    const location                                                                  = useLocation();
    // useRef

    useEffect(() => {
        // document.getElementsByTagName("html").onClick = () => {
        //     console.log();
        // }

        function handleClickOutside(event) {
            if (shoppingRef.current && !shoppingRef.current.contains(event.target)) {
                setShopping(false);
            }
            if (accountRef.current && !accountRef.current.contains(event.target)) {
                setAccount(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
    }, []);

    // LOGIC===============================================================================================================
    // RENDER==============================================================================================================
    return(
        <header className="w-full">     
            <Modal state={modal} setState={setModal} message={message}/>
            <nav className="flex justify-between">
                <div className="flex justify-start items-center">
                    {location.pathname !== "/" &&
                        <>
                            <img src={logo} alt="logo" className="md:w-20 w-10 md:ml-10 ml-5 mt-5"/>
                            <label className="font-Raleway ml-3 mt-5 md:text-3xl text-xl">Club de las <br/>margaritas</label>
                        </>
                    }
                </div>
                <div className="lg:hidden">
                    <button className="navbar-burger flex items-center text-slate-600 p-3" onClick={() => {setMenuMovil(true)}}>
                        <svg className="block h-8 w-8 fill-current mt-6 mr-5" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <title>Mobile menu</title>
                            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                        </svg>
                    </button>
                </div>
                <ul className={menuMovil ? "absolute bg-background w-[27rem] h-[30rem] z-50" : `flex hidden lg:flex font-bold`}>
                    {menuMovil && 
                        <li className="flex justify-end">
                            <button className="flex items-center text-slate-600 p-5 pr-8" onClick={() => {setMenuMovil(false)}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="2.1rem" height="2.1rem" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeWidth="1.5"><circle cx="12" cy="12" r="10"/><path strokeLinejoin="round" d="m14.5 9.5l-5 5m0-5l5 5"/></g></svg>
                            </button>
                        </li>
                    }
                    <li>
                        <NavLink 
                            to="/"
                            className={({ isActive }) => {
                                return isActive ? "px-10 py-4 align-middle flex justify-center bg-pantone-green text-white duration-300 items-center border-pantone-green border-b" : "px-10 py-4 align-middle flex justify-center hover:bg-pantone-green hover:text-white duration-300 items-center border-pantone-green border-b"
                            }}
                            onClick={() => {
                                menuMovil(false);
                            }}
                        >
                            Inicio
                        </NavLink>
                    </li>
                    <li>
                        <NavLink 
                            to="/contact"
                            className={({ isActive }) => {
                                return isActive ? "px-10 py-4 align-middle flex justify-center bg-pantone-green text-white duration-300 items-center border-pantone-green border-b" : "px-10 py-4 align-middle flex justify-center hover:bg-pantone-green hover:text-white duration-300 items-center border-pantone-green border-b"
                            }}
                            onClick={() => {
                                menuMovil(false);
                            }}
                        >
                            Contacto
                        </NavLink>  
                    </li>
                    <li>
                        <NavLink 
                            to="/about-us"
                            className={({ isActive }) => {
                                return isActive ? "px-10 py-4 align-middle flex justify-center bg-pantone-green text-white duration-300 items-center border-pantone-green border-b" : "px-10 py-4 align-middle flex justify-center hover:bg-pantone-green hover:text-white duration-300 items-center border-pantone-green border-b"
                            }}
                            onClick={() => {
                                menuMovil(false);
                            }}
                        >
                            ¿Quienes somos?
                        </NavLink>  
                    </li>
                    {auth &&
                        <li>
                            <NavLink 
                                to="/catalog"
                                onClick={() => {
                                    menuMovil(false);
                                }}
                                className={({ isActive }) => {
                                    return isActive ? "px-10 py-4 align-middle flex justify-center bg-pantone-green text-white duration-300 items-center border-pantone-green border-b" : "px-10 py-4 align-middle flex justify-center hover:bg-pantone-green hover:text-white duration-300 items-center border-pantone-green border-b"
                                }}
                            >
                                Catalógo
                            </NavLink>
                        </li>
                    }
                    {(auth && rol) &&
                        <li>
                            <NavLink 
                                to="/admin"
                                onClick={() => {
                                    menuMovil(false);
                                }}
                                className={({ isActive }) => {
                                    return isActive ? "px-10 py-4 align-middle flex justify-center bg-pantone-green text-white duration-300 items-center border-pantone-green border-b" : "px-10 py-4 align-middle flex justify-center hover:bg-pantone-green hover:text-white duration-300 items-center border-pantone-green border-b"
                                }}
                            >
                                Herraminetas de administración
                            </NavLink>
                        </li>
                    }
                    {auth &&
                        <>
                            <li>
                                {menuMovil ?
                                    <Link to="/cart" className="w-full px-5 py-4 align-middle flex justify-center hover:bg-pantone-green hover:text-white duration-300 items-center border-pantone-green border-b" tabIndex="-1" onClick={() => {
                                        setShopping(false);
                                        menuMovil(false);
                                    }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 1 0 0 4a2 2 0 0 0 0-4Zm-8 2a2 2 0 1 1-4 0a2 2 0 0 1 4 0Z"/></svg></Link>
                                    :
                                    <>
                                        <button 
                                            type="button" 
                                            className={`px-5 py-4 align-middle flex justify-center hover:bg-pantone-green hover:text-white duration-300 items-center border-pantone-green border-b ${shpping && "bg-pantone-green text-white"}`}
                                            onClick={() => {
                                                setShopping(!shpping);
                                                setAccount(false);
                                            }}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 1 0 0 4a2 2 0 0 0 0-4Zm-8 2a2 2 0 1 1-4 0a2 2 0 0 1 4 0Z"/></svg>
                                        </button>
                                        
                                        {shpping &&
                                            <div className="absolute right-0 z-10 w-96 bg-pantone-green text-background border border-background" tabIndex="-1" ref={shoppingRef}>
                                                <div className="py-5">
                                                    <h1 className="border-b w-1/2 m-auto text-center text-xl">Lista de pedido</h1>
                                                </div>
                                                <div>
                                                    <table>
                                                        <tbody>
                                                            {
                                                                cart.map((item, key) => {
                                                                    return (
                                                                        <tr key={`cart-item-${key}`}>
                                                                            <td className="p-1 px-6 italic font-thin">{item.name}</td>
                                                                            <td className="p-1">
                                                                                <div className="flex m-auto">
                                                                                    <button type="button" className="p-1 h-11" onClick={() => {
                                                                                        eventCart(item, "-");
                                                                                    }}>
                                                                                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
                                                                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h16"/>
                                                                                        </svg>
                                                                                    </button>
                                                                                    <input 
                                                                                        type="text" 
                                                                                        className="bg-transparent h-11 text-center w-10" 
                                                                                        placeholder="999" 
                                                                                        required 
                                                                                        value={item.qty} 
                                                                                        onChange={(e) => {
                                                                                            eventCart(item, e.target.value);
                                                                                        }}
                                                                                    />
                                                                                    <button type="button" className="p-1 h-11" onClick={() => {
                                                                                        eventCart(item, "+");
                                                                                    }}>
                                                                                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                                                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16"/>
                                                                                        </svg>
                                                                                    </button>
                                                                                </div>
                                                                            </td>
                                                                            <td className="p-1 px-6">${parseInt(item.price) * item.qty}</td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="py-5 flex">
                                                    <Link to="/cart" className="px-4 py-2 bg-background m-auto text-pantone-green" tabIndex="-1" onClick={() => {
                                                        setShopping(false);
                                                    }}>Encargar</Link>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                                
                            </li>
                            <li>
                                {!menuMovil && 
                                    <button 
                                        type="button" 
                                        className={menuMovil ? "w-full px-5 py-4 align-middle flex justify-center hover:bg-pantone-green hover:text-white duration-300 items-center border-pantone-green border-b" : "px-5 py-4 align-middle flex justify-center hover:bg-pantone-green hover:text-white duration-300 items-center border-pantone-green border-b"}
                                        onClick={() => {
                                            setAccount(!account);
                                            setShopping(false);
                                        }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeWidth="2"><path d="M17.796 20.706c-.342-1.063-1.096-2.003-2.143-2.673C14.605 17.363 13.32 17 12 17c-1.32 0-2.605.363-3.653 1.033c-1.047.67-1.8 1.61-2.143 2.673"/><circle cx="12" cy="10" r="3" strokeLinecap="round"/><rect width="18" height="18" x="3" y="3" rx="3"/></g></svg>
                                    </button>
                                }
                                {(account || menuMovil) &&
                                    <div className={menuMovil ? "absolute right-0 z-10 w-full h-full bg-pantone-green text-background border border-background" : "absolute right-0 z-10 w-96 bg-pantone-green text-background border border-background"} tabIndex="-1" ref={accountRef}>
                                        <div className="py-5">
                                            <h1 className="border-b w-1/2 m-auto text-center text-xl">Perfil</h1>
                                        </div>
                                        <div className="w-full px-4 py-2 text-center">
                                            {user.name}
                                            <br/>
                                            {user.last_name}
                                        </div>
                                        <div className="w-full px-4 py-2 text-center">
                                            <div className="pb-8 pt-4 w-full">
                                                <label className="relative inline-flex items-center cursor-pointer">
                                                    <input type="checkbox" checked={newsLetter} onChange={async (e) => {                            
                                                        setNewsLetter(e.target.checked);

                                                        let formData            = new FormData();
                                                        
                                                        formData.append("id", user.id);
                                                        formData.append("notification", e.target.checked ? 1 : 0);

                                                        let request             = await axios({
                                                            method              : "post",
                                                            url                 : "https://clubdelasmargaritas.org/api/user/setnotify",
                                                            headers             : { 
                                                                "Content-Type"  : "multipart/form-data" 
                                                            },
                                                            data                : formData
                                                        });   

                                                        if(request.status !== 200){
                                                            console.log(request);
                                                            setMessage(request.data);
                                                            setModal(true);
                                                            return;
                                                        }
                            
                                                        if(request.data.error){
                                                            setMessage(request.data.response);
                                                            setModal(true);
                                                            return;
                                                        }
                            
                                                        setMessage(request.data.response);
                                                        setModal(true);                                                        

                                                    }} className="sr-only peer"/>
                                                    <div className="w-11 h-6 bg-background peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-yellow-100 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-yellow-custom"></div>
                                                    <span className="ms-3 font-medium">Recibir noticias</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="py-5 flex">
                                            <button 
                                                className="px-4 py-2 bg-background m-auto text-pantone-green" 
                                                tabIndex="-1"
                                                onClick={() => {
                                                    setLoading(true);
                                                    localStorage.removeItem("token");
                                                    setAuth("");
                                                    setLoading(false);
                                                    setAccount(false);
                                                }}    
                                            >Cerrar sessión</button>
                                        </div>
                                    </div>
                                }
                            </li>
                        </>
                    }
                </ul>
            </nav>
        </header>
    );
}