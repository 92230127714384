// IMPORT==================================================================================================================
import { useEffect, useState }                                                      from "react";
import axios                                                                        from "axios";
import { Link }                                                                     from "react-router-dom";
import { formatRut, getCheckDigitRut }                                              from "../assets/js/utils";
// COMPONENTS==============================================================================================================
import { Button }                                                                   from "../components/Button";
import { Modal, ModalForm }                                                         from "./../components/Modal";
import { Input }                                                                    from "./../components/Input";

export function Admin({setLoading}) {

    const [modal, setModal]         = useState(false);
    const [modalCR, setModalCR]     = useState(false);
    const [modalCA, setModalCA]     = useState(false);
    const [message, setMessage]     = useState("");
    const [messageM, setMessageM]   = useState(false);
    const [asunto, setAsunto]       = useState("");
    const [file, setFilte]          = useState();

    const [type, setType]                                                           = useState("119");
    const [fechaEmi, setFechaEmi]                                                   = useState(0);
    const [duracionReceta, setduracionReceta]                                       = useState(0);
    const [gramajeMensual, setGramajeMensual]                                       = useState(false);
    const [gramaje, setGramaje]                                                     = useState("");
    const [gramajeDias, setGramajeDias]                                             = useState("7");
    const [gramajeCantidad, setGramajeCantidad]                                     = useState("0");
    const [diagnostico, setDiagnostico]                                             = useState("");
    const [nrmReceta, setNrmReceta]                                                 = useState("");
    const [receta, setReceta]                                                       = useState("");
    const [rut, setRut]                                                             = useState("");

    const [fechaExpCertificado, setFechaExpCertificado]                             = useState(0);
    const [certificado, setCertificado]                                             = useState("");

    return (
        <section className="w-full py-10">
            <Modal state={messageM} setState={setMessageM} message={message}/>
            <ModalForm state={modal} setState={setModal}>
                <div className="flex flex-wrap">
                    <div className="w-full md:px-10">
                        <h1 className="text-3xl md:text-3xl font-thin border-b-2 border-pantone-green py-3 w-3/4 m-auto text-center md:m-0 md:text-start md:w-2/6">NewsLetter</h1>
                        <form className="flex flex-wrap justify-center py-2 md:py-6 transition-all duration-300 ease-in-out">
                            <div className="flex flex-wrap justify-center overflow-hidden w-full">
                                <div className="w-full px-2 md:px-0">
                                    {/* <Input type="text" onInput={(e) => {setTitle(e.target.value)}} value={title} placeholder="Ingrese titulo" title="Titulo"></Input> */}
                                    <Input type="text" onInput={(e) => {setAsunto(e.target.value)}} value={asunto} placeholder="Ingrese el titulo" title="Titulo"></Input>
                                    <Input type="file" onChange={(e) => {setFilte(e.target.files[0])}} title="Imagen del NewsLetter" accept="image/png, image/jpeg"></Input>
                                </div>
                            </div>
                            <Button 
                                className="md:text-xl md:px-16 mt-10 md:mt-20"
                                onClick={async(e) => {
                                    e.preventDefault();
                                    try{
                                        let formData                    = new FormData();

                                        formData.append("asunto",       asunto);
                                        formData.append("img",          file);

                                        setLoading(true);

                                        let request             = await axios({
                                            method              : "post",
                                            url                 : "https://clubdelasmargaritas.org/api/user/sendnews",
                                            headers             : { 
                                                "Content-Type"  : "multipart/form-data" 
                                            },
                                            data                : formData
                                        });

                                        setLoading(false);

                                        if(request.status !== 200){
                                            console.log(request);
                                            setMessage(request.data.response);
                                            setMessageM(true);
                                            return;
                                        }

                                        setMessage(request.data.response);
                                        setMessageM(true);
                                        setModal(false);
                                    }catch(ex){
                                        console.log(ex);
                                    }
                                }}
                            >
                                Enviar NewsLetter
                            </Button>
                        </form>
                    </div>
                </div>   
            </ModalForm>
            <ModalForm state={modalCR} setState={setModalCR}>
                <div className="flex flex-wrap">
                    <div className="w-full md:px-10">
                        <h1 className="text-3xl md:text-3xl font-thin border-b-2 border-pantone-green py-3 w-3/4 m-auto text-center md:m-0 md:text-start md:w-2/6">Cargar receta</h1>
                        <form className="flex flex-wrap justify-center py-2 md:py-6 transition-all duration-300 ease-in-out">
                            <div className="flex flex-wrap justify-center overflow-hidden w-full">
                                <div className="w-full px-2 md:px-0">
                                <Input type="text" onInput={(e) => {setRut(e.target.value)}} value={rut !== "" ? formatRut(rut) : rut}  placeholder="Ingrese su rut" title="Rut"></Input>
                                <Input type="date" onInput={(e) => {setFechaEmi(e.target.value)}} placeholder="Ingrese la duración de la receta" title="Fecha emisión de receta"></Input>
                                <Input type="number" onInput={(e) => {setduracionReceta(e.target.value)}} placeholder="Ingrese duración de la receta" title="Duración de la receta (En meses)"></Input>
                                {type === "119" ? 
                                    <Input type="number" onInput={(e) => {setGramaje(e.target.value)}} placeholder={`Ingrese el gramaje de consumo ${gramajeMensual ? "mensual" : "diario"}`} title={`Gramaje ${gramajeMensual ? "mensual" : "diario"}`} switch={true} onChange={(e) => {
                                        setGramajeMensual(e.target.checked);
                                    }}></Input>
                                    :
                                    <div className="flex flex-wrap">
                                        <div className="py-2 flex flex-wrap w-full items-center md:w-1/2 md:pr-5">
                                            <label className="text-xl font-thin mb-2">Tipo de medicación</label>                                
                                            <select 
                                                className="bg-background border-2 border-pantone-green w-full p-1"
                                                onChange={async (e) => {
                                                    setGramajeDias(e.target.value);
                                                }}    
                                            >
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7" selected>7</option>
                                            </select>
                                        </div>
                                        <div className="py-2 flex flex-wrap w-full items-center md:w-1/2">
                                            <label className="text-xl font-thin mb-2">Cantidad de gramaje</label>
                                            <select 
                                                className="bg-background border-2 border-pantone-green w-full p-1" 
                                                onChange={async (e) => {
                                                    setGramajeCantidad(e.target.value);
                                                }}    
                                            >
                                                <option value="0">0</option>
                                                <option value="0.05">0.05</option>
                                                <option value="0.1">0.1</option>
                                                <option value="0.2">0.2</option>
                                            </select>
                                        </div>
                                    </div>
                                }
                                <Input type="text" onInput={(e) => {setDiagnostico(e.target.value)}} placeholder="Ingrese su diagnostico" title="Diagnostico (Opcional)"></Input>
                                <Input type="number" onInput={(e) => {setNrmReceta(e.target.value)}} placeholder="Ingrese el numero de la receta" title="Numero de la receta (Opcional)"></Input>
                                <Input type="file" onInput={(e) => {setReceta(e.target.files[0])}} placeholder="Ingrese su receta medica" title="Receta medica" accept="image/jpeg,image/png,application/pdf"></Input>
                                </div>
                            </div>
                            <Button 
                                className="md:text-xl md:px-16 mt-10 md:mt-20"
                                onClick={async(e) => {
                                    e.preventDefault();
                                    try{
                                        let formData                    = new FormData();

                                        formData.append("run",                      formatRut(rut).replace(/\./g, ""));
                                        formData.append("receta",                   receta);
                                        formData.append("diagnostico",              diagnostico);
                                        formData.append("numero_receta",            nrmReceta);
                                        formData.append("fecha_emi_receta",         fechaEmi);

                                        if(type === "119"){
                                            formData.append("gramos_mensuales",     (gramaje * 30));
                                        }else{
                                            if(gramajeCantidad === "0"){
                                                setMessage("ingrese una cantidad validad de gramaje");
                                                setModal(true);
                                                return
                                            }
            
                                            formData.append("gramos_mensuales[0]",  gramajeCantidad);
                                            formData.append("gramos_mensuales[1]",  (parseInt(gramajeDias) * parseFloat(gramajeCantidad) * 4).toFixed(1));
                                        }

                                        setLoading(true);

                                        let request             = await axios({
                                            method              : "post",
                                            url                 : "https://clubdelasmargaritas.org/api/form/upreceta",
                                            headers             : { 
                                                "Content-Type"  : "multipart/form-data" 
                                            },
                                            data                : formData
                                        });

                                        setLoading(false);

                                        if(request.status !== 200){
                                            console.log(request);
                                            setMessage(request.data.response);
                                            setMessageM(true);
                                            return;
                                        }

                                        setMessage(request.data.response);
                                        setMessageM(true);
                                        setModal(false);
                                    }catch(ex){
                                        console.log(ex);
                                    }
                                }}
                            >
                                Cargar receta
                            </Button>
                        </form>
                    </div>
                </div>   
            </ModalForm>
            <ModalForm state={modalCA} setState={setModalCA}>
                <div className="flex flex-wrap">
                    <div className="w-full md:px-10">
                        <h1 className="text-3xl md:text-3xl font-thin border-b-2 border-pantone-green py-3 w-3/4 m-auto text-center md:m-0 md:text-start md:w-2/6">Cargar Certficado</h1>
                        <form className="flex flex-wrap justify-center py-2 md:py-6 transition-all duration-300 ease-in-out">
                            <div className="flex flex-wrap justify-center overflow-hidden w-full">
                                <div className="w-full px-2 md:px-0">
                                <Input type="text" onInput={(e) => {setRut(e.target.value)}} value={rut !== "" ? formatRut(rut) : rut}  placeholder="Ingrese su rut" title="Rut"></Input>
                                <Input type="date" onInput={(e) => {setFechaExpCertificado(e.target.value)}} placeholder="Ingrese la fecha de expiración" title="Fecha expiración del certificado"></Input>
                                <Input type="file" onInput={(e) => {setCertificado(e.target.files[0])}} title="Certificado antecedente" accept="application/pdf"></Input>
                                </div>
                            </div>
                            <Button 
                                className="md:text-xl md:px-16 mt-10 md:mt-20"
                                onClick={async(e) => {
                                    e.preventDefault();
                                    try{
                                        let formData                    = new FormData();

                                        formData.append("run",                      formatRut(rut).replace(/\./g, ""));
                                        formData.append("certificado",              certificado);
                                        formData.append("fecha_exp_antecedente",    fechaExpCertificado);

                                        setLoading(true);

                                        let request             = await axios({
                                            method              : "post",
                                            url                 : "https://clubdelasmargaritas.org/api/form/upantecedente",
                                            headers             : { 
                                                "Content-Type"  : "multipart/form-data" 
                                            },
                                            data                : formData
                                        });

                                        setLoading(false);

                                        if(request.status !== 200){
                                            console.log(request);
                                            setMessage(request.data.response);
                                            setMessageM(true);
                                            return;
                                        }

                                        setMessage(request.data.response);
                                        setMessageM(true);
                                        setModal(false);
                                    }catch(ex){
                                        console.log(ex);
                                    }
                                }}
                            >
                                Cargar antecedente
                            </Button>
                        </form>
                    </div>
                </div>   
            </ModalForm>
            <h1 className="border-b-4 border-pantone-green p-5 text-2xl lg:w-2/6 w-10/12 text-center m-auto">Herramienta de admin</h1>
            <div className="lg:w-2/6 w-10/12 p-5 m-auto">
                <Link 
                    to          = "/userAdmin"
                    className   = "px-10 py-4 mb-4 align-middle flex justify-center hover:bg-pantone-green hover:text-white duration-300 items-center border-pantone-green border"
                >
                    Usuarios
                </Link>
                <Link 
                    to          = "/productAdmin"
                    className   = "px-10 py-4 mb-4 align-middle flex justify-center hover:bg-pantone-green hover:text-white duration-300 items-center border-pantone-green border"
                >
                    Producto
                </Link>
                <Link 
                    to          = "/pedidoAdmin"
                    className   = "px-10 py-4 mb-4 align-middle flex justify-center hover:bg-pantone-green hover:text-white duration-300 items-center border-pantone-green border"
                >
                    Pedido
                </Link>
                <button 
                    className   = "px-10 py-4 mb-4 align-middle flex justify-center hover:bg-pantone-green hover:text-white duration-300 items-center border-pantone-green border w-full"
                    onClick     = {() => {
                        setModal(true);
                    }}
                >
                    NewsLetter
                </button>
                <button 
                    className   = "px-10 py-4 mb-4 align-middle flex justify-center hover:bg-pantone-green hover:text-white duration-300 items-center border-pantone-green border w-full"
                    onClick     = {() => {
                        setModalCR(true);
                    }}
                >
                    Cargar receta
                </button>
                <button 
                    className   = "px-10 py-4 align-middle flex justify-center hover:bg-pantone-green hover:text-white duration-300 items-center border-pantone-green border w-full"
                    onClick     = {() => {
                        setModalCA(true);
                    }}
                >
                    Cargar antecedente
                </button>
            </div>
        </section>
    );
}

export function UserAdmin({setLoading}) {

    const [list, setList]           = useState([]);
    const [user, setUser]           = useState(1);
    const [modal, setModal]         = useState(false);
    const [deleteM, setDeleteM]     = useState(false);
    const [refuseM, setRefuseM]     = useState(false);
    const [detailsM, setDetailsM]   = useState(false);
    const [details, setDetails]     = useState({});
    const [idUser, setidUser]       = useState(0);
    const [refuse, setRefuse]       = useState("");
    const [message, setMessage]     = useState("");

    useEffect(() => {
        const get               = async (url) => {
            let request             = await axios({
                method              : "post",
                url                 : url
            });

            if(request.data.error){
                console.log(request.data);
                return;
            }

            setList(Array.isArray(request.data.response) ? request.data.response : []);
        }
        get(user === 1 ? "https://clubdelasmargaritas.org/api/user/get" : (user === 2 ? "https://clubdelasmargaritas.org/api/form/getr" : "https://clubdelasmargaritas.org/api/form/getd"));
    }, [user]);

    return (
        <> 
            <Modal state={modal} setState={setModal} message={message}/>
            <ModalForm state={refuseM} setState={setRefuseM}>
                <h1 className="w-full text-3xl pb-3">Motivo del rechazo</h1>
                <textarea
                    className="border-2 border-pantone-green p-2.5 rounded w-full text-xl bg-transparent"
                    cols="30"
                    rows="10"
                    placeholder="Escribe en este cuadro de texto"
                    onInput={(e) => setRefuse(e.target.value)}
                ></textarea>
                <Button onClick={async () => {
                    
                    setLoading(true);
                    let request             = await axios({
                        method              : "post",
                        url                 : "https://clubdelasmargaritas.org/api/form/refused",
                        data                : {
                            id              : idUser,
                            motivo          : refuse
                        }
                    });
        
                    if(request.data.error){
                        console.log(request.data);
                        return;
                    }
                    setLoading(false);
                    setRefuseM(false);
                    setMessage("Se le ha notificado al usuario por su rechazo");
                    setList(list.filter((itemF) => itemF.id !== idUser));
                    setModal(true);

                }}>Rechazar</Button>
            </ModalForm>
            <ModalForm state={deleteM} setState={setDeleteM}>
                <h1 className="w-full text-3xl pb-3">¿Desea eliminar este usuario?</h1>
                <Button className="w-full mt-5" onClick={async () => {
                    
                    setLoading(true);

                    let request             = await axios({
                        method              : "post",
                        url                 : "https://clubdelasmargaritas.org/api/user/deletef",
                        data                : {
                            id              : idUser
                        }
                    });
        
                    if(request.data.error){
                        console.log(request.data);
                        return;
                    }

                    setLoading(false);
                    setDeleteM(false);
                    setMessage("Se ha eliminado el usuario");
                    setList(list.filter((itemF) => itemF.id !== idUser));
                    setModal(true);

                }}>Eliminar</Button>
            </ModalForm>
            <ModalForm state={detailsM} setState={setDetailsM}>
                {
                    Object.keys(details).length > 0 &&
                    <div className="flex flex-wrap justify-center py-2 md:py-6 transition-all duration-300 ease-in-out">
                        <div className="w-full px-2 md:px-0 md:w-1/2 md:pr-14">
                            <Input type="text" readOnly value={formatRut(details.attributes.declaracion.datos.run)} title="Rut"></Input>
                            <Input type="text" readOnly value={details.name} title="Nombre completo"></Input>
                            <Input type="text" readOnly value={details.last_name} title="Apellido completo"></Input>
                            <Input type="text" readOnly value={details.attributes.declaracion.datos.direccion_paciente[0]} title="Dirección"></Input>
                            <Input type="text" readOnly value={details.attributes.declaracion.datos.direccion_paciente[1]} title="Dirección adicional"></Input>
                            <Input type="email" readOnly value={details.email} title="Correo electrónico"></Input>
                            <Input type="number" readOnly value={details.phone} title="Teléfono"></Input>
                        </div>
                        <div className="w-full px-2 md:px-0 md:w-1/2 md:pl-10">                    
                            <Input type="text" readOnly value={details.attributes.declaracion.datos.tipo_dispensado} title="Tipo de medicación"></Input>
                            <Input type="date" readOnly value={details.attributes.declaracion.datos.fecha_nacimiento} title="Fecha de nacimiento"></Input>
                            <Input type="date" readOnly value={details.attributes.declaracion.datos.fecha_exp_receta} title="Expiración de la receta"></Input>
                            <Input type="date" readOnly value={new Date(parseInt(details.attributes.declaracion.datos.tiempo_dispensado)).toISOString().split("T")[0]} title="Tiempo de dispensa"></Input>
                            <Input type="number" readOnly value={parseInt(details.attributes.declaracion.datos.gramos_mensuales)} title="Gramaje diario"></Input>
                            <Input type="text" readOnly value={details.attributes.declaracion.datos.diagnostico} title="Diagnostico"></Input>
                            <Input type="number" readOnly value={details.attributes.declaracion.datos.numero_receta === "SIN NÚMERO" ? 0 : details.attributes.declaracion.datos.numero_receta} title="Numero de la receta"></Input>
                        </div>
                        <div className="pt-5 flex flex-wrap">
                            <a href={`https://clubdelasmargaritas.org/api/files/documents/${details.username}/receta/receta_${details.username}.pdf`} target="_blank" className="text-yellow-500 underline pt-4 w-full text-center">Ver receta</a>
                            {details.password !== "" && <a href={`https://clubdelasmargaritas.org/api/files/documents/${details.username}/declaracion/${details.attributes.declaracion.datos.tipo_dispensado}/${details.username}_declaracion${details.attributes.declaracion.datos.tipo_dispensado === "119" ? "CAN" : "PSI"}.pdf`} target="_blank" className="text-yellow-500 underline pt-4 w-full text-center">Ver documento</a>}
                        </div>
                    </div>
                }                
            </ModalForm>
            <section className="w-full mt-10 py-10">
                <div className="flex justify-around pb-4 px-4">
                    <button 
                        className={user === 1 ? "border-4 border-pantone-green p-2 bg-pantone-green text-background font-medium mx-1" : "border-4 border-pantone-green p-2 hover:bg-pantone-green hover:text-background font-medium mx-1"}
                        onClick={() => {setUser(1)}}
                    >
                        Usuario habilitados
                    </button>
                    <button 
                        className={user === 2 ? "border-4 border-pantone-green p-2 bg-pantone-green text-background font-medium mx-1" : "border-4 border-pantone-green p-2 hover:bg-pantone-green hover:text-background font-medium mx-1"}
                        onClick={() => {setUser(2)}}
                    >
                        Formulario de receta (Paso 1)
                    </button>
                    <button 
                        className={user === 3 ? "border-4 border-pantone-green p-2 bg-pantone-green text-background font-medium mx-1" : "border-4 border-pantone-green p-2 hover:bg-pantone-green hover:text-background font-medium mx-1"}
                        onClick={() => {setUser(3)}}
                    >
                        declaración y suscripción (Paso 2)
                    </button>
                    {/* <button 
                        className={user === 4 ? "border-4 border-pantone-green p-2 bg-pantone-green text-background font-medium mx-1" : "border-4 border-pantone-green p-2 hover:bg-pantone-green hover:text-background font-medium mx-1"}
                        onClick={() => {setUser(4)}}
                    >
                        Confirmar suscripción (Paso 3)
                    </button> */}
                </div>
                <div className="overflow-x-auto px-4">
                    <table className="min-w-full divide-y-2 divide-pantone-green text-sm border-2 border-pantone-green">
                        <thead>
                            <tr>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-left">RUT</th>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-left">Nombre</th>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-left">Apellido</th>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-left">Email</th>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-left">Telefono</th>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-left">Dirección</th>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-left">Acciones</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y-2 divide-pantone-green">
                            {(list.length === 0) ?
                                <>
                                    <tr>
                                        <td className="py-2 px-4 text-center" colSpan={7}>no hay registros.</td>
                                    </tr>
                                </>                                
                                :
                                list.map((item, key) => {

                                    if(item.enabled === "0" && user === 1){
                                        return;
                                    }

                                    if(item.attributes.suscripcion.estado_pago === "" && (user === 4)){
                                        return;
                                    }

                                    if((item.attributes.suscripcion.estado_pago === "pending" || item.attributes.suscripcion.estado_pago === "done") && (user === 3)){
                                        return;
                                    }

                                    if((user === 4) && item.password === ""){
                                        return;
                                    }

                                    return(
                                        <tr key={key}>
                                            <td className="py-2 px-4">{item.attributes.declaracion.datos.run}</td>
                                            <td className="py-2 px-4">{item.name}</td>
                                            <td className="py-2 px-4">{item.last_name}</td>
                                            <td className="py-2 px-4">{item.email}</td>
                                            <td className="py-2 px-4">{item.phone}</td>
                                            <td className="py-2 px-4">{item.attributes.declaracion.datos.direccion_paciente.join(" | ")}</td>
                                            <td className="py-2 px-4 w-[20rem]">                                                
                                                {user === 1 ? 
                                                    <>
                                                        <Button onClick={() => {
                                                            setidUser(item.id);
                                                            setDeleteM(true);
                                                        }}>Eliminar</Button>
                                                        <Button onClick={() => {
                                                            setDetails(item);
                                                            setDetailsM(true);
                                                        }}>Detalle</Button>
                                                    </> 
                                                    : 
                                                    <>
                                                        {(user === 3  && item.password === "" || item.attributes.suscripcion.estado_pago === "pending") ?
                                                            <label className="mx-2">{item.attributes.suscripcion.estado_pago === "pending" ? "por pagar" : "En espera"}</label>
                                                            :
                                                            <Button onClick={async() => {                                                                                                                    
                                                                
                                                                setLoading(true);
    
                                                                let request             = await axios({
                                                                    method              : "post",
                                                                    url                 : user === 2 ? `https://clubdelasmargaritas.org/api/form/acceptr/${item.id}` : (user === 4 ? "https://clubdelasmargaritas.org/api/touser" : `https://clubdelasmargaritas.org/api/suscripcion/pago/${item.id}`),
                                                                    data                : {
                                                                        id              : item.id
                                                                    }
                                                                });
                                                    
                                                                if(request.data.error){
                                                                    console.log(request.data);
                                                                    return;
                                                                }
    
                                                                setList(list.filter((itemF) => itemF.id !== item.id));                                                                
                                                                setLoading(false);
                                                                setModal(true);
                                                                setMessage("Usuario confirmado, le llegará un correo al usuario");
    
                                                            }}>{user === 3 ? "Enviar link" : "Confirmar"}</Button>                                                            
                                                        }
                                                        <Button onClick={() => {
                                                            setRefuseM(true)
                                                            setidUser(item.id);
                                                        }}>Rechazar</Button>
                                                        <Button onClick={() => {
                                                            setDetails(item);
                                                            setDetailsM(true);
                                                        }}>Detalle</Button>
                                                    </> 
                                                }
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    );
}

export function ProductAdmin({setLoading}) {

    const [list, setList]               = useState([]);
    const [modal, setModal]             = useState(false);
    const [messageM, setMessageM]       = useState(false);
    const [message, setMessage]         = useState("");
    const [idProduct, setIdProduct]     = useState(0);
    const [code, setCode]               = useState("");
    const [name, setName]               = useState("");
    const [price, setPrice]             = useState("");
    const [description, setDescription] = useState("");
    const [stock, setStock]             = useState(0);
    const [content, setContent]         = useState("");
    const [img, setImg]                 = useState("");
    const [deleteM, setDeleteM]         = useState(false);
    const get                           = async () => {
        let request                     = await axios({
            method                      : "post",
            url                         : "https://clubdelasmargaritas.org/api/product/getf"
        });

        if(request.data.error){
            console.log(request.data);
            return;
        }
        
        setList(Array.isArray(request.data.response) ? request.data.response : []);

    };

    useEffect(() => {
        get();
    }, []);

    return (
        <> 
            <Modal state={messageM} setState={setMessageM} message={message}/>
            <ModalForm state={deleteM} setState={setDeleteM}>
                <h1 className="w-full text-3xl pb-3">¿Desea eliminar este prducto?</h1>
                <Button className="w-full mt-5" onClick={async () => {
                    
                    setLoading(true);

                    let request             = await axios({
                        method              : "post",
                        url                 : "https://clubdelasmargaritas.org/api/product/deletef",
                        data                : {
                            id              : idProduct
                        }
                    });
        
                    if(request.data.error){
                        console.log(request.data);
                        return;
                    }

                    setLoading(false);
                    setDeleteM(false);
                    setMessage("Se ha eliminado el producto");
                    setList(list.filter((itemF) => itemF.id !== idProduct));
                    setMessageM(true);

                }}>Eliminar</Button>
            </ModalForm>
            <ModalForm state={modal} setState={setModal} className="w-9/12">
                <div className="flex flex-wrap">
                    <div className="w-full md:px-10">
                        <h1 className="text-3xl md:text-3xl font-thin border-b-2 border-pantone-green py-3 w-3/4 m-auto text-center md:m-0 md:text-start md:w-2/6">{idProduct === 0 ? "Agregar" : "Editar"} Producto</h1>
                        <form className="flex flex-wrap justify-center py-2 md:py-6 transition-all duration-300 ease-in-out">
                            <div className="flex flex-wrap justify-center overflow-hidden w-full">
                                <div className="w-full px-2 md:px-0 md:w-1/2 md:pr-14">
                                    <Input type="text" onInput={(e) => {setCode(e.target.value)}} value={code} placeholder="Ingrese el codigo del producto" title="Codigo producto"></Input>
                                    <Input type="text" onInput={(e) => {setName(e.target.value)}} value={name} placeholder="Ingrese el nombre del producto" title="Nombre producto"></Input>
                                    <Input type="number" onInput={(e) => {setPrice(e.target.value)}} value={price} placeholder="Ingrese el precio del producto" title="Precio producto"></Input>
                                </div>
                                <div className="w-full px-2 md:px-0 md:w-1/2 md:pl-10">
                                    <Input type="number" onInput={(e) => {setStock(e.target.value)}} value={stock} placeholder="Ingrese el stock del producto" title="Stock producto"></Input>
                                    <div className="py-2 flex flex-wrap w-full items-center">
                                        <label className="text-xl font-thin mb-2">Tipo de medicación</label>
                                        <select 
                                            className="bg-background border-2 border-pantone-green w-full p-1" 
                                            onChange={async (e) => {
                                                setContent(e.target.value);
                                            }}
                                            value={content}
                                        >
                                            <option value="119">CANNABIS</option>
                                            <option value="120" >PSILOCIBINA</option>
                                        </select>
                                    </div>
                                    {/* <Input type="text" onInput={(e) => {setContent(e.target.value)}} value={content} placeholder="Ingrese el contenido del producto" title="Contenido producto"></Input> */}
                                    <Input type="file" onChange={(e) => {setImg(e.target.files[0])}} title="Imagen del producto" accept="image/png, image/jpeg"></Input>
                                </div>
                                <div className="w-full px-2 md:px-0">
                                    <h1 className="w-full text-2xl font-thin pb-3">Descripción del producto</h1>
                                    <textarea
                                        className="border-2 border-pantone-green p-2.5 rounded w-full text-xl bg-transparent"
                                        cols="30"
                                        rows="5"
                                        placeholder="Ingrese una descripción del producto"
                                        onInput={(e) => setDescription(e.target.value)}
                                        value={description}
                                    >{description}</textarea>
                                </div>
                            </div>
                            <Button 
                                className="md:text-xl md:px-16 mt-10 md:mt-20"
                                onClick={async(e) => {
                                    e.preventDefault();
                                    try{
                                        let formData            = new FormData();
                                        
                                        if(idProduct !== 0){
                                            formData.append("id",           idProduct);
                                        }
                                        
                                        formData.append("nombre",       name);
                                        formData.append("codigo",       code);
                                        formData.append("precio",       price);
                                        formData.append("descripcion",  description);
                                        formData.append("stock",        stock);
                                        formData.append("type",         content);
                                        formData.append("image",        img);

                                        setLoading(true);

                                        let request             = await axios({
                                            method              : "post",
                                            url                 : idProduct === 0 ? "https://clubdelasmargaritas.org/api/product/create" : "https://clubdelasmargaritas.org/api/product/update",
                                            headers             : { 
                                                "Content-Type"  : "multipart/form-data" 
                                            },
                                            data                : formData
                                        });

                                        setLoading(false);

                                        if(request.status !== 200){
                                            console.log(request);
                                            return;
                                        }

                                        setMessage(request.data.response);
                                        get();
                                        setMessageM(idProduct === 0 ? "Se ha agregado el producto" : "Se ha modificado el producto");
                                        setModal(false);
                                        setIdProduct(0);

                                    }catch(ex){
                                        console.log(ex);
                                    }
                                }}
                            >
                                {idProduct === 0 ? "Agregar" : "Editar"} producto
                            </Button>
                        </form>
                    </div>
                </div>                
            </ModalForm>
            <section className="w-full mt-10 py-10">
                <div className="flex justify-around pb-4">
                    <Button onClick={() => {setModal(true)}}>Agregar nuevo producto</Button>
                </div>
                <div className="overflow-x-auto px-4">
                    <table className="min-w-full divide-y-2 divide-pantone-green text-sm border-2 border-pantone-green">
                        <thead>
                            <tr>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-left">Codigo</th>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-left">Nombre</th>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-left">Precio</th>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-left">Descripcion</th>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-left">Stock</th>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-left">Acciones</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y-2 divide-pantone-green">
                            {(list.length === 0) ?
                                <>
                                    <tr>
                                        <td className="py-2 px-4 text-center" colSpan={7}>no hay registros.</td>
                                    </tr>
                                </>      
                                :
                                list.map((item, key) => {

                                    if(item.enabled === "0"){
                                        return;
                                    }

                                    return(
                                        <tr key={key}>
                                            <td className="py-2 px-4">{item.attributes.codigo}</td>
                                            <td className="py-2 px-4">{item.attributes.nombre}</td>
                                            <td className="py-2 px-4">{item.attributes.precio}</td>
                                            <td className="py-2 px-4">{item.attributes.descripcion}</td>
                                            <td className="py-2 px-4">{item.attributes.stock}</td>
                                            <td className="py-2 px-4">
                                                <Button onClick={() => {
                                                    setIdProduct(item.id);
                                                    setCode(item.attributes.codigo);
                                                    setName(item.attributes.nombre);
                                                    setPrice(item.attributes.precio);
                                                    setDescription(item.attributes.descripcion);
                                                    setStock(item.attributes.stock);
                                                    setContent(item.attributes.tipo_dispensado);
                                                    setModal(true);
                                                }}>Editar</Button>
                                                <Button onClick={() => {
                                                    setIdProduct(item.id);
                                                    setDeleteM(true);
                                                }}>Eliminar</Button>
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    );
}

export function PedidoAdmin({setLoading}) {

    const [list, setList]           = useState([]);
    const [open, setOpen]           = useState(false);

    const handleSetIframeValue = () => {
        const iframe                = document.getElementById("iframe");

        if (iframe) {
          const iframeDocument      = iframe.contentDocument || iframe.contentWindow.document;
          const inputInsideIframe   = iframeDocument.getElementById("run");
          
          if (inputInsideIframe) {
            inputInsideIframe.value = "65201149-7";
          }

        }

    };

    useEffect(() => {
        const get                   = async () => {
            let request             = await axios({
                method              : "post",
                url                 : "https://clubdelasmargaritas.org/api/pedido/get"
            });

            if(request.data.error){
                console.log(request.data);
                return;
            }
            
            setList(Array.isArray(request.data.response) ? request.data.response : []);
        }

        get();
        
    }, []);

    return (
        <> 
            <ModalForm state={open} setState={setOpen}>
                <iframe
                    id          = "iframe"
                    title       = "Contenido"
                    className   = "w-[100vh]"
                    src         = "https://starkenpro.cl/service/emision-form"
                    width       = {1000}
                    height      = {800}
                    // frameBorder="0"
                    allowFullScreen
                />
            </ModalForm>
            <section className="w-full mt-10 py-10">            
                
                <div className="overflow-x-auto px-4">
                    <table className="min-w-full divide-y-2 divide-pantone-green text-sm border-2 border-pantone-green">
                        <thead>
                            <tr>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-left">Usuario</th>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-left">Fecha de solicitud</th>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-left">Fecha envio</th>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-left">Dirección</th>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-left">Detalle</th>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-left">Metodo de donación</th>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-left">Total</th>
                                <th className="whitespace-nowrap px-4 py-2 font-medium text-left">Estado</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y-2 divide-pantone-green">
                            {(list.length === 0) ?
                                <>
                                    <tr>
                                        <td className="py-2 px-4 text-center" colSpan={7}>no hay registros.</td>
                                    </tr>
                                </> 
                                :
                                list.map((item, key) => {
                                    return(
                                        <tr className="h-14" key={key}>
                                            <td className="py-2 px-4">{item.id_user}</td>
                                            <td className="py-2 px-4">{item.fecha_solicitud}</td>
                                            <td className="py-2 px-4">{item.fecha_envio}</td>
                                            <td className="py-2 px-4">{item.direccion}</td>
                                            <td className="py-2 px-4">----</td>
                                            <td className="py-2 px-4">{item.metodo_pago}</td>
                                            <td className="py-2 px-4">${item.total}</td>
                                            <td className="py-2 px-4">{item.estado === "1" ? "Pendiente" : "Enviado"}</td>
                                            {item.estado === "1" && <td className="py-2 px-4"><Button onClick={() => {
                                                // handleSetIframeValue();
                                                setOpen(true);
                                            }}>Generar pedido</Button></td>}
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    );
}

export function NewsLetterAdmin({setLoading}) {

    const [list, setList]           = useState([]);
    const [open, setOpen]           = useState(false);

    const handleSetIframeValue = () => {
        const iframe                = document.getElementById("iframe");

        if (iframe) {
          const iframeDocument      = iframe.contentDocument || iframe.contentWindow.document;
          const inputInsideIframe   = iframeDocument.getElementById("run");
          
          if (inputInsideIframe) {
            inputInsideIframe.value = "65201149-7";
          }

        }

    };

    useEffect(() => {
        const get                   = async () => {
            let request             = await axios({
                method              : "post",
                url                 : "https://clubdelasmargaritas.org/api/pedido/get"
            });

            if(request.data.error){
                console.log(request.data);
                return;
            }
            
            setList(Array.isArray(request.data.response) ? request.data.response : []);
        }

        get();
        
    }, []);

    return (
        <> 
            <ModalForm state={open} setState={setOpen}>
                <iframe
                    id          = "iframe"
                    title       = "Contenido"
                    className   = "w-[100vh]"
                    src         = "https://starkenpro.cl/service/emision-form"
                    width       = {1000}
                    height      = {800}
                    // frameBorder="0"
                    allowFullScreen
                />
            </ModalForm>
            <section className="w-full mt-10 py-10">            
            </section>
        </>
    );
}