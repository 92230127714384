export function Button(props) {
    // RENDER==============================================================================================================
    return (
        <button 
            {...props}
            className={`rounded border p-2 px-5 bg-pantone-green text-background hover:opacity-70 duration-200 ${props.className}`}
        >
            {props.children}
        </button>
    );
}
