// IMPORT==================================================================================================================
import { useState }                                                                 from "react";
// IMG=====================================================================================================================
import logo                                                                         from "../assets/img/logo.png";
// COMPONENTS==============================================================================================================
import { Button }                                                                   from "./../components/Button";
import { Acount }                                                                   from "./User";

export function Home({auth, setAuth, setLoading}) {
    // VARIABLES===========================================================================================================
    const [dialog, setDialgo]                                                       = useState(false);
    const [tab, setTab]                                                             = useState("");
    // RENDER==============================================================================================================
    return (
        <>
            {!auth && <Acount state={dialog} setState={setDialgo} setAuth={setAuth} setLoading={setLoading} tab={tab}/>}
            <section className="w-full mt-10 py-10 flex flex-wrap justify-center">
                <img src={logo} alt="logo" className="w-60"/>
                <div className="text-center py-10 pt-14">
                    <label className="font-Raleway text-6xl px-2">Club de las <br/>margaritas</label>
                </div>
                {!auth && 
                    <div className="w-full flex flex-wrap justify-center py-20">
                        <Button 
                            className="md:text-xl md:px-16 md:mr-10 px-[3rem] mr-3"
                            onClick={() => {
                                setDialgo(true);
                                setTab("Signup");
                            }}
                            >
                            Postula aquí
                        </Button>
                        <Button 
                            className="md:text-xl md:px-16 md:ml-10 px-[3rem] ml-3"
                            onClick={() => {
                                setDialgo(true);
                                setTab("Login");
                            }}
                        >
                            Soy miembro
                        </Button>
                    </div>
                }
            </section>
        </>
    );
}

export function AboutUs() {
    return (        
        <section id="about-us" className="w-full">
            <h1 className="border-b-4 border-pantone-green p-5 text-2xl lg:w-2/6 w-10/12 text-center m-auto">¿Quienes somos?</h1>
            <p className="lg:w-2/6 w-10/12 p-5 m-auto">
            Somos una Asociación sin fines de lucro joven, motivados por el bienestar humano y su inmanente desarrollo. Buscamos hacer aportes integrales a la sociedad, promoviendo y educando en el uso de plantas medicinales y espirituales, para el desarrollo de una vida consciente y plena <br/><br/> 
            Protegemos, resguardamos y defendemos el uso de medicinas naturales para tratar síntomas refractarios a la medicina convencional.
            <br/><br/>
            Historia: Hemos sido  testigos de lo que nos ha ocurrido como sociedad en las últimas décadas :  A pasos agigantados hemos ido olvidando nuestro cuerpo físico, emocional y espiritual en medio de la velocidad de las ciudades y los exigentes sistemas de vida. <br/><br/>
            En nuestra propia búsqueda por comprender la enfermedad; hemos llegado a medicinas ancestrales, encontrando en ellas compasión, sanación y conciencia. <br/><br/>
            Hoy nos inspira cultivar, compartir, enseñar y asistir; para así ser testigos de mejores sociedades, aliviadas del dolor, conectadas con la tierra y su medicina; conectadas al regalo del presente. <br/><br/>
            Nuestro Objetivo es la promoción y el desarrollo de actividades con el propósito de colaborar de manera integral al tratamiento de todo tipo de patologías, mejoramiento de la calidad de vida y salud en general de todos los Asociados, con el uso de plantas medicinales que la misma Fundación cultiva.entos y dosis aplicadas a los usuarios. Desarrollar lo que podamos, para aportar a la salud general de cada miembro inscrito. <br/><br/>
            </p>
            <img src={logo} alt="logo" className="w-20 m-auto mb-10 mt-5 opacity-20"/>
        </section>
    );
}

export function Required() {
    return (        
        <section id="about-us" className="w-full">
            <h1 className="border-b-4 border-pantone-green p-5 text-2xl lg:w-2/6 w-10/12 text-center m-auto">Requisitos</h1>
            <p className="lg:w-2/6 w-10/12 p-5 m-auto">
                Somos una asociación sin fines de lucro, dedicada al cultivo de estas medicinas. <br/>
                La normativa vigente en el país no permite venta, por lo que funcionamos con inscripción previa para un grupo privado de miembros, <br/>
                que presentan patologías compatibles con psilocibe o cannabis. Lo anterior se llama dispensación y requiere de inscripción.
                <br/><br/>
                Para inscripción debes adjuntar la siguiente documentación y al finalizar, pagar una cuota de membresía de $8.000.
            </p>
            <div className="lg:w-2/6 w-10/12 p-5 px-9 m-auto">
                <ol>
                    <li>Cédula de identidad vigente por ambos lados (escaneo o foto legible)</li>
                    <li>Receta médica vigente, indicando la cantidad mensual máxima de consumo</li>
                    <li>Certificado de antecedentes especiales vigente (a renovar cada 6 meses) <br/><a href="https://www.registrocivil.cl/principal/servicios-en-linea" className="text-yellow-500 underline pt-4 w-full text-center">Obtener certificado de antecedente</a></li>
                    <li>Declaración Especial de Ingreso (formulario que debes completar en línea)</li>
                </ol>
            </div>
            <p className="lg:w-2/6 w-10/12 p-5 m-auto">
                Es fundamental y obligatorio, mantener los documentos renovables, como receta médica y certificado de antecedentes, al día.
                <br/><br/>
                Si aceptas, puedes subir la documentación a la página web, y así cursar tu membresía. Lo anterior te permitirá dispensar tus recetas durante un año seguido.
                <br/><br/>
                Los valores de psilocibina, a continuación:
            </p>
            <ul className="lg:w-2/6 w-10/12 p-5 m-auto text-center">
                <li className="py-2 font-bold">Microdosis 50mg (0,05gr)</li>
                <li>10 capsulas 50mg $20.000</li>
                <li>20 capsulas 50mg $25.000</li>
                <li>30 capsulas 50 mg $30.000</li>
                <li className="py-2 font-bold">Microdosis 100mg (0,1gr):</li>
                <li>10 capsulas $20.000</li>
                <li>20 capsulas $30.000</li>
                <li>30 capsulas $40.000</li>
                <li className="py-2 font-bold">Microdosis 200mg (0,2gr):</li>
                <li>10 capsulas $25.000</li>
                <li>20 capsulas $45.000</li>
                <li>30 capsulas $65.000</li>
                <li className="py-2 font-bold">Dosis</li>
                <li>1 gramo seco $10.000</li>
                <li className="py-2 font-bold">Cannabis sativa</li>
                <li>1 gramo seco $8.000</li>
            </ul>
            <p className="lg:w-2/6 w-10/12 p-5 m-auto">
            Una vez inscrit@, podrás dispensar tu receta desde tu cuenta, en la página web. Los envíos se realizan por starken, actualmente no tenemos punto de retiro.
            </p>
            <img src={logo} alt="logo" className="w-20 m-auto mb-10 mt-5 opacity-20"/>
        </section>
    );
}