// IMPORT==================================================================================================================
import { useEffect, useRef }                                                            from "react";
import { useNavigate }                                                                  from "react-router";

// IMG=====================================================================================================================
// COMPONENTS==============================================================================================================

export function Loading({state, setState}) {
    // VARIABLES===========================================================================================================
    const dialog                        = useRef();
    // LOGIC===============================================================================================================    
    // const onClose                       = () => {
    //     setState(false);
    // }
    useEffect(() => {
        if(dialog.current !== undefined){
            if(state){
                dialog.current.showModal();
                document.querySelector("html").classList.add("overflow-hidden");
            }else{
                setTimeout(() => {
                    dialog.current.close();
                    document.querySelector("html").classList.remove("overflow-hidden");
                }, 200);
            }
        }
    }, [state]);
    // RENDER==============================================================================================================
    return (
        <dialog className="backdrop:bg-pantone-green backdrop:opacity-80 bg-background" ref={dialog}>
            <div className="flex flex-wrap p-10">
                Cargando...
            </div>
        </dialog>
    );
}


export function Modal({state, setState, message, goBack = false}) {
    // VARIABLES===========================================================================================================
    const dialog                        = useRef();
    const navigate                      = useNavigate();
    // LOGIC===============================================================================================================    
    const onClose                       = () => {
        setState(false);
        if(goBack){
            navigate(-1);
        }
    }
    useEffect(() => {
        if(dialog.current !== undefined){
            if(state){
                dialog.current.showModal();
                document.querySelector("html").classList.add("overflow-hidden");
            }else{
                setTimeout(() => {
                    dialog.current.close();
                    document.querySelector("html").classList.remove("overflow-hidden");
                }, 200);
            }
        }
    }, [state]);
    // RENDER==============================================================================================================
    return (
        <dialog className="backdrop:bg-pantone-green backdrop:opacity-80 bg-background" ref={dialog}>
            <div className="flex flex-wrap p-10 w-full">
                <button 
                    className="text-xl absolute top-0 right-0 m-2"
                    onClick={onClose}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeWidth="1.5"><circle cx="12" cy="12" r="10"/><path strokeLinejoin="round" d="m14.5 9.5l-5 5m0-5l5 5"/></g></svg>
                </button>
                <div>
                    {message}
                </div>
            </div>
        </dialog>
    );
}

export function ModalForm({state, setState, className, children}) {
    // VARIABLES===========================================================================================================
    const dialog                        = useRef();
    // LOGIC===============================================================================================================    
    const onClose                       = () => {
        setState(false);
    }
    useEffect(() => {
        if(dialog.current !== undefined){
            if(state){
                dialog.current.showModal();
                document.querySelector("html").classList.add("overflow-hidden");
            }else{
                setTimeout(() => {
                    dialog.current.close();
                    document.querySelector("html").classList.remove("overflow-hidden");
                }, 200);
            }
        }
    }, [state]);
    // RENDER==============================================================================================================
    return (
        <dialog className={`backdrop:bg-pantone-green backdrop:opacity-80 bg-background ${className}`} ref={dialog}>
            <div className="flex flex-wrap p-10 w-full">
                <button 
                    className="text-xl absolute top-0 right-0 m-2"
                    onClick={onClose}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeWidth="1.5"><circle cx="12" cy="12" r="10"/><path strokeLinejoin="round" d="m14.5 9.5l-5 5m0-5l5 5"/></g></svg>
                </button>
                <div>
                    {children}
                </div>
            </div>
        </dialog>
    );
}